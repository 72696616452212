@import "../../variables";

.inputBlock {
  position: relative;
  width: 100%;
  line-height: 14px !important;
  font-family: $main-fonts;
  //margin-bottom: 24px;
}

.ghostInputBlock {
  position: relative;
  display: inline-block;
  line-height: 14px !important;
  font-family: $main-fonts;
}

.label {
  position: relative;
  display: inline-block;
  margin-bottom: 8px;
  color: $black;
  transition: all .2s ease;
  cursor: text;
  font-size: 14px;
  pointer-events: none;
  user-select: none;
}

.inputContainer{
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  height: 40px;
  width: 100%;
  outline: 0;
  border: 1px solid $input-border;
  border-radius: 5px;
  font-size: 14px;
  box-sizing: border-box !important;
  transition: all .2s ease;
}

// Ghost Input

.ghostInputContainer {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  height: 40px;
  outline: 0;
  border: 1px solid rgba(0,0,0,0);
  border-radius: 5px;
  font-size: 20px;
  box-sizing: border-box !important;
  transition: all .2s ease;
}

.ghostWidth {
  font-size: 20px !important;
  position: absolute !important;
  opacity: 0 !important;
  pointer-events: none;
}

.input {
  display: flex;
  height: 100%;
  width: 100%;
  outline: 0;
  border: none;
  border-radius: 5px;
  padding: 0 28px 0 12px;
  font-size: 14px;
  box-sizing: border-box !important;
  transition: all .2s ease;
}

.ghostInput {
  display: flex;
  height: 100%;
  outline: 0;
  font-family: $main-fonts;
  min-width: 220px;
  max-width: 380px;
  border: none;
  border-radius: 5px;
  padding: 0 28px 0 12px;
  font-size: 20px;
  box-sizing: border-box !important;
  transition: all .2s ease;
}

input.ghostInput {
  transition: none;
}

// input hover
.inputBlock:hover > .inputContainer {
  border: 1px solid $input-hover-border;
}

.ghostInputBlock:hover > .ghostInputContainer {
  border: 1px solid $input-hover-border;
}

// input focused
.focused .inputContainer,
.focused .ghostInputContainer {
  border: 1px solid $input-hover-border;
  box-shadow: $input-focus-shadow;
}

.focused .label {
  color: $black !important;
  border: none;
}

// non empty
.nonEmpty > .label {
  color: $label-color;
}

// input disabled
.disabled .inputContainer,
.disabled .ghostInputContainer {
  background-color: $disabled-input;
  border: 1px solid $disabled-input !important;
  pointer-events: none;
  transition: none;
}

.disabled .inputContainer .input,
.disabled .ghostInputContainer .ghostInput {
  background-color: $disabled-input !important;
}

.disabled:hover .inputContainer,
.disabled:hover .ghostInputContainer {
  border: none;
  transition: none;
}

.disabled .validationMessage {
  visibility: hidden !important;
}

.disabled .clearButton {
  visibility: hidden !important;
}

.disabled .label {
  color: $label-color !important;
}

// sizes
.sm > .inputContainer,
.sm > .ghostInputContainer {
  height: 36px !important;
}

.lg > .inputContainer,
.lg > .ghostInputContainer {
  height: 48px !important;
}

// clear button
.clearButton {
  width: 8px;
  height: 100%;
  position: absolute;
  right: 12px;
  bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease;
}

.clearButton i {
  font-size: 8px !important;
  color: $clear-button-color;
  padding: 4px 11px;
  position: relative;
}

.inputBlock.focused.nonEmpty .clearButton,
.ghostInputBlock.focused.nonEmpty .clearButton {
  opacity: 1;
  visibility: visible;
  transition: opacity .2s ease;
}

.inputBlock.nonEmpty:hover .clearButton,
.ghostInputBlock.nonEmpty:hover .clearButton {
  opacity: 1;
  visibility: visible;
  transition: opacity .2s ease;
}

.clearButton i:hover {
  color: $black;
}

//.sm .clearButton {
//  height: 36px !important;
//}
//
//.lg .clearButton {
//  height: 48px !important;
//}

// validation
.success .inputContainer,
.success .ghostInputContainer {
  border: 1px solid $input-success;
}

.focused.success .inputContainer,
.focused.success .ghostInputContainer {
  border: 1px solid $input-success;
}

.success .inputContainer:hover,
.success .ghostInputContainer:hover {
  border: 1px solid $input-success;
}

.focused.success .inputContainer,
.focused.success .ghostInputContainer {
  box-shadow: $input-success-shadow;
}

.warning .inputContainer,
.warning .ghostInputContainer {
  border: 1px solid $input-warning;
}

.focused.warning .inputContainer,
.focused.warning .ghostInputContainer {
  border: 1px solid $input-warning;
}

.warning .inputContainer:hover,
.warning .ghostInputContainer:hover {
  border: 1px solid $input-warning;
}

.focused.warning .inputContainer,
.focused.warning .ghostInputContainer {
  box-shadow: $input-warning-shadow;
}

.error .inputContainer,
.error .ghostInputContainer {
  border: 1px solid $input-error;
}

.focused.error .inputContainer,
.focused.error .ghostInputContainer {
  border: 1px solid $input-error;
}

.error .inputContainer:hover,
.error .ghostInputContainer:hover {
  border: 1px solid $input-error;
}

.focused.error .inputContainer,
.focused.error .ghostInputContainer {
  box-shadow: $input-error-shadow;
}

// validation message
.validationMessage {
  position: relative;
  padding: 4px 0;
  left: 0;
  font-size: 12px;
  visibility: hidden;
}

.success .validationMessage {
  color: $input-success;
  visibility: visible;
}

.warning .validationMessage {
  color: $input-warning;
  visibility: visible;
}

.error .validationMessage {
  color: $input-error;
  visibility: visible;
}

//remove placeholder when input focus
.input::-webkit-input-placeholder {
  color: $placeholder-color !important;
  font-family: $main-fonts;
}

.input:focus::-webkit-input-placeholder {
  text-indent: -999px
}

.input::-moz-placeholder {
  color: $placeholder-color !important;
  font-family: $main-fonts;
}

.input:focus::-moz-placeholder {
  text-indent: -999px
}

.input:-moz-placeholder {
  color: $placeholder-color !important;
  font-family: $main-fonts;
}

.input:focus:-moz-placeholder {
  text-indent: -999px
}

//appended class
.appended .input,
.appended .ghostInput {
  padding-right: 12px !important;
}

.appendedAvatar .input {
  padding-left: 44px !important;
}

.appendAvatarItem {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

