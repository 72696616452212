@import "../../variables";

// quick reply block
.QuickReplyBlock {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  display: flex;
  padding: 0 24px;
  flex-wrap: nowrap;
  box-sizing: border-box;
  font-family: $main-fonts;
  z-index: 9999;
  transform: translateY(-100%);
  visibility: hidden;
}

.QuickReplyBlock p {
  margin: 0;
}

// quick reply
.QuickReply {
  position: relative;
  bottom: -4px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  background-color: $white;
  transition: all 0.2s ease;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transform: scale(1, 0);
  transform-origin: bottom right;
  box-shadow: 0 0 22.08px 1.92px rgba(28, 30, 37, 0.15), 0 0 1px 0 rgba(28, 30, 37, 0.03);
}

// quick reply header
.QuickReplyHeader {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid $light-700;
  font-size: 16px;
  box-sizing: border-box;
}

.QuickReplyHeader p {
  max-width: 70%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

// quick reply container
.QuickReplyContainer {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

// quick reply item
.QuickReplyItem {
  width: 100%;
  height: 40px;
  min-height: 40px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  font-size: 14px;
  transition: background-color .2s ease;
}

.QuickReplyItem p {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

// hover item
.QuickReplyItem:hover {
  background-color: $light-600;
}

// quick reply show
.show {
  visibility: visible;
}

.show .QuickReply {
  transition: all 0.2s ease;
  z-index: 9999;
  opacity: 1;
  visibility: visible;
  transform: scale(1, 1);
}
