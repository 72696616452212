@import "../../variables";

// tabs container
.tabsContainer {
  position: relative;
  border-bottom: 1px solid $light-700;
  font-family: $main-fonts;
  font-size: 14px;
}

// tabs
.tabs {
  position: relative;
  width: 100%;
}

.listTabs {
  padding-left: 0;
  list-style: none;
  margin: 0;
  min-height: 40px;
  display: inline-flex;
}

.tabsLi {
  display: flex;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.tabsLi:last-of-type {
  margin: 0 !important;
}

// tab
.tab {
  position: relative;
  text-transform: capitalize;
  color: $light-100;
  font-weight: 400;
  //padding: 0 12px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 100px;
  max-width: 212px;
  min-height: 40px;
  text-decoration: none;
  box-sizing: border-box;
  transition: color .2s ease;
}

.tab:hover {
  color: $black;
}

// active tab
.active {
  color: $black !important;
  //font-weight: 500;
}

// active tab border
//.tab:before {
//  content: '';
//  width: 100%;
//  height: 2px;
//  background-color: $black;
//  position: absolute;
//  bottom: -1px;
//  opacity: 0;
//  transition: opacity .2s ease;
//}
//
//.tab.active:before {
//  opacity: 1;
//}

// tabs position center
.positionCenter .tabsContainer {
  display: flex;
  justify-content: center;
}

// tabs content
.tabContent {
  font-family: $main-fonts;
  font-size: 14px;
}

.spaceBetween .listTabs {
  justify-content: space-between;
  display: flex;
}

.h100 {
  height: 100%;
}

.sticky .tabsContainer {
  position: relative;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1;
}