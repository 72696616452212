@import "../../variables";

.button {
  position: relative;
  min-width: 100px;
  height: 40px;
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  padding: 0 20px 1px 20px;
  transition: all .2s ease, padding 0s;
  outline: 0;
  font-family: $main-fonts;
}

// primary default button
.primary {
  background-color: $btn-primary;
  border: 1px solid $btn-primary;
  color: $white;
}

.primary:hover {
  background-color: $btn-primary-hover;
  border: 1px solid $btn-primary-hover;
}

.primary:active {
  background-color: $btn-primary-hover;
  border-color: $btn-primary-hover;
  box-shadow: $btn-outline-shadow;
}

.primary i {
  color: $white;
  font-size: 14px;
  margin-left: 12px;
}

// outline
.outline {
  background-color: $white;
  border: 1px solid $btn-outline;
  color: $black;
}

.outline:hover {
  border-color: $light-200;
  background-color: $white;
  color: $black;
}

.outline:active {
  border-color: $light-200;
  box-shadow: $btn-outline-shadow;
}

.outline i {
  color: #7d7e80;
  font-size: 14px;
  margin-left: 12px;
}

// white
.white {
  background-color: transparent;
  border: 1px solid $white;
  color: $white;
}

.white:hover {
  border: 1px solid $white;
  background-color: $white!important;
  color: $black;
}

.white:active {
  border: 1px solid $white;
  box-shadow: 0 0 0 2px #7d7e80;
}

.white i {
  color: $white;
  font-size: 14px;
  margin-left: 12px;
  transition: all .2s ease;
}

.white:hover i {
  color: $black;
}

// sizes
.sm {
  height: 36px;
}

.lg {
  height: 48px;
}

.responsive {
  width: 100%;
}

// disabled
.disabled {
  background-color: $btn-disabled;
  border: 1px solid $btn-disabled;
  color: #4b4b4d;
  pointer-events: none;
  user-select: none;
}

.primary.disabled i {
  color: #4b4b4d;
}

//button with icon
.iconButton {
  padding: 10px;
  min-width: auto;
  width: 40px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.iconButton i {
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.iconButton i:before {
  padding: 0;
  margin: 0;
}

.iconButton.lg {
  width: 48px;
}

.iconButton.sm {
  width: 36px;
}

.iconButton.lg i {
  font-size: 16px;
}


// group button
.groupButton {
  position: relative;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 40px;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  padding: 0 12px 1px 12px;
  outline: 0;
  border-radius: 0;
  background-color: $white !important;
  border: 1px solid $btn-outline !important;
  color: $black !important;
  transition: none;
  border-right: none !important;
  font-family: $main-fonts;
  box-sizing: border-box;
}

.groupButton:first-of-type {
  border-radius: 5px 0 0 5px !important;
  border-right: none !important;
}

.groupButton:last-of-type {
  border-radius: 0 5px 5px 0 !important;
  border-right: 1px solid $btn-outline !important;
}

.groupButton:hover {
  background-color: $btn-outline !important;
}

.groupButton:focus {
  border-color: $btn-primary !important;
  background-color: $btn-primary !important;
  box-shadow: none !important;
  color: $white !important;
}

.groupButton:active {
  border-color: $btn-primary !important;
  background-color: $btn-primary !important;
  box-shadow: none !important;
}

// group button sizes
.sm {
  height: 36px;
}

.lg {
  height: 48px;
}

// group button disabled
.disabled {
  background-color: $btn-disabled !important;
  border: 1px solid $btn-disabled !important;
  color: #4b4b4d;
  pointer-events: none;
  user-select: none;
  transition: none;
}


//processing
.processing {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button.processing  {
  padding-bottom: 0 !important;
  pointer-events: none;
}

.button.disabled.processing  {
  background-color: $btn-primary !important;
  border: 1px solid $btn-primary !important;
  color: $white !important;
}

.button.outline.disabled.processing  {
  background-color: $btn-disabled!important;
  border: 1px solid $btn-disabled!important;
  color: #4b4b4d;
  pointer-events: none;
  user-select: none;
}

//active
.active {
  border-color: $btn-primary !important;
  background-color: $btn-primary !important;
  box-shadow: none !important;
  color: $white !important;
}

.active:hover {
  background-color: $btn-primary-hover!important;
}

.active:active {
  background-color: $btn-primary-hover!important;
}