@import "../../variables";

// popup header
.popupHeader {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 24px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}
