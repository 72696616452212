.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 24px;
}

.content:first-child {
    height: 48px !important;
}
