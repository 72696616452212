.check {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    width: 100%;
    border: 1px solid #d9dbde;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: opacity .3s ease;
    padding: 0 15px
}

.icon {
    position: relative;
    margin-right: 15px;
}

.title {
    position: relative;
    font-weight: 500;
    width: 100%;
}

.indicate {
    position: absolute;
    right: 15px;
    color: #000;
    transition: opacity .3s ease;
    opacity: 0;
}

.check:hover .indicate {
    opacity: 1;
}

.checkbox {
    opacity: 0;
}
