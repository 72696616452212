@import "../../variables";

// side panel overlay
.sidePanelOverlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(27, 30, 38, .5);
  transition: z-index .4s ease .4s, opacity .1s ease .2s;
  opacity: 0;
  z-index: -2;
}

//side panel
.sidePanel {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 24px;
  //z-index: 998;
  box-sizing: border-box;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transition: transform 0.2s cubic-bezier(0.2, 0, 0, 1) 0.1s;
  will-change: transform;
  font-family: $main-fonts;
}

//show overlay and side panel
.open {
  opacity: 1;
  z-index: 1001;
  transition: opacity .4s ease;
}

.sidePanelOverlay.open .sidePanel {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: transform 0.2s cubic-bezier(0.2, 0, 0, 1) 0.2s;
}

// side panel header
.sidePanelHeader {
  width: 100%;
  height: 80px;
  min-height: 80px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.sidePanelHeaderButtons {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.sidePanelHeaderButtons i {
  cursor: pointer;
  color: #7d7e80;
  font-size: 14px;
  transition: all .2s ease;
  padding: 8px;
  position: relative;
  right: -8px;
}

.sidePanelHeaderButtons i:hover {
  color: #000;
}

.sidePanelHeader h6 {
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0 0 2px 0;
}

// divider
.sidePanelDivider {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 600;
  margin: 24px 0 12px 0;
}

.sidePanelDivider:first-child {
  margin: 0 0 12px 0;
}

.dateDividerDay {
  margin-right: 6px;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding: 0 0 2px 0;
}

.sidePanelContent {
  width: 100%;
}

.sidePanelContent.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sidePanelContent.empty p {
  font-size: 18px;
  margin: 16px 0;
}

.sidePanelDivider i {
  width: 20px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  cursor: pointer;
  font-size: 2px!important;
  color: #97989b;
  transition: all .2s ease;
}

.sidePanelDivider i:hover {
  color: #000;
}

.noPadding .sidePanel {
  padding: 0;
}