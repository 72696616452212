@import "../../variables";

// smart reply container
.SmartReplyContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  display: none;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: nowrap;
  padding: 0 24px;
  box-sizing: border-box;
  font-family: $main-fonts;
  z-index: 9999;
  transform: translateY(-100%);
}

.show {
  display: flex;
}

// smart reply
.SmartReply {
  border-radius: 5px;
  background-color: #dbddf0;
  color: $black;
  font-size: 12px;
  padding: 12px;
  min-height: 72px;
  max-height: 72px;
  min-width: 100px;
  width: 33%;
  max-width: 400px;
  margin-right: 10px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all .2s;
  position: relative;
  bottom: -4px;
  cursor: pointer;
  animation: fadeInRight .4s .1s;
  animation-fill-mode: both;
  box-shadow: 0 10px 9px 1px rgba(76, 87, 179, 0.1);
}

// smart reply p
.SmartReply p {
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  user-select: none;
}

// hover
.SmartReply:hover {
  max-height: 152px;
  margin-bottom: -8px!important;
}

.SmartReply:hover > p {
  -webkit-line-clamp: 8;
}

// remove margin in last reply
.SmartReply:last-of-type {
  margin: 0;
}

// active
.SmartReply:active {
  background-color: #c9cce8;
}

// animation show reply
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


