@font-face {
    font-family: 'SFUIText';
    font-style: normal;
    font-weight: 400;
    src: local('SF UI Text Regular'), url('SFUIText-Regular.woff') format('woff');
}

@font-face {
    font-family: 'SFUIText';
    font-style: normal;
    font-weight: 500;
    src: local('SF UI Text Semibold'), url('SFUIText-Semibold.woff') format('woff');
}