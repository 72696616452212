.container {
    position: absolute;
    top: 62px;
    right: 0;
    width: 100%;
}

/*.container > div div:nth-child(1) {*/
/*    position: absolute;*/
/*    right: 0;*/
/*    width: 60px;*/
/*    height: 40px;*/
/*}*/

/*.container > div div:nth-child(2) {*/
/*    position: absolute;*/
/*    top: 50px;*/
/*}*/
