@import "../../variables";

// side bar
.sideBar {
  display: flex;
  flex-direction: column;
  width: 60px;
  height: 100%;
  min-height: 500px;
  background: $white;
  color: $black;
  border-left: 1px solid #d9dbde;
  right: 0;
  top: 0;
  position: fixed;
  z-index: 1000;
  box-sizing: border-box;
  font-family: $main-fonts;
}

// side bar item
.sideBarItem {
  width: 100%;
  box-sizing: border-box;
}

// notification counter
.notificationCounter {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}

// notification counter label
.counterLabel {
  border-radius: 50%;
  background-color: #bf2546;
  box-shadow: 0 5px 4.75px 0.25px rgba(27, 30, 38, .2);
  position: absolute;
  right: 19px;
  top: 29px;
  width: 8px;
  height: 8px;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: opacity .2s ease;
}

// notification counter icon
.notificationCounter svg {
  fill: #7d7e80;
  transition: all .1s ease;
}

.notificationCounter:hover > svg {
  fill: $black;
}

// notification counter active
.notificationCounter.active .counterLabel {
  visibility: visible;
  z-index: inherit;
  opacity: 1;
}