.wrap {
    position: relative;
}

.wrap textarea {
    height: 125px;
    pointer-events: auto !important;
    box-shadow: none !important;
}

.wrap:hover .copy {
    opacity: 1;
    visibility: visible;
}

.copy {
    position: absolute;
    background: none;
    border: none;
    font-size: 12px;
    color: #999;
    outline: none;
    cursor: pointer;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: .2s ease;
    z-index: 10;
}
