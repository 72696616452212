.icon {
    position: absolute;
    right: 24px;
    width: 20px;
    height: 20px;
    opacity: 1;
    transition: .4s ease;
    border-radius: 50%;
    border: 1px solid #d4d5d9;
}

.icon polyline,
.icon circle {
    opacity: 0;
    transition: .4s ease;
}

.active polyline,
.active circle {
    opacity: 1;
}