.container {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.label {
    font-size: 14px;
    line-height: 1;
    color: #000
}

.tooltip {
    cursor: pointer;
    position: relative;
    margin-left: 10px;
    top: 2px;
}

.tooltip svg path {
    fill: #9d9ea0
}

.tooltip:hover svg path {
    fill: #333;
}

.fill .label {
 color: #9d9ea0;
}

.noGutter {
    margin: 0;
}

.noGutter .tooltip {
    margin: 0;
}
