@import "../../variables";

// metric
.metric {
  display: inline-flex;
  flex-direction: column;
  max-width: 212px;
  min-width: 150px;
  width: 100%;
  height: 62px;
  font-family: $main-fonts;
}

.block {
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.value {
  color: $black;
  font-weight: 600;
  font-size: 24px;
}

.text {
  color: $light-100;
  font-size: 11px;
  padding-top: 8px;
  text-transform: uppercase;
}

.metric p {
  padding: 0;
  margin: 0;
}

.value p {
  max-width: 112px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.text p {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}


// metric label
.metricLabel {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  width: auto;
  min-width: 40px;
  height: 26px;
  padding: 8px;
  border-radius: 5px;
  box-sizing: border-box;
}

// metric value
.metricValue p {
  font-size: 12px;
  line-height: 12px;
  padding: 0;
  margin: 0;
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

// icon caret icon block
.icon {
  display: flex;
  margin-left: 6px;
}

// status styles
// positive
.negative .metricValue p {
  color: #d00045;
}

.metricLabel.negative {
  background-color: #fae5ec;
}

.negative .icon svg {
  fill: #d00045;
}

// neutral
.neutral .metricValue p {
  color: #d2b00f;
}

.metricLabel.neutral {
  background-color: #faf7e7;
}

.neutral .icon svg {
  fill: #d2b00f;
}

// negative
.positive .metricValue p {
  color: #58a44a;
}

.metricLabel.positive {
  background-color: #eef6ed;
}

.positive .icon svg {
  fill: #58a44a;
}

// result progress / regress
.increase .icon svg {
  transform: rotate(0);
}

.decrease .icon svg {
  transform: rotate(180deg);
}

@media screen and (max-width: 1439px) {
  .value {
    font-size: 20px;
  }
  .text {
    font-size: 10px;
  }
}