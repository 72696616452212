@import "../../variables";

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

.container {
  width: 100%;
  padding-right: $gutter-width;
  padding-left: $gutter-width;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1180px;
  }
}

@media (min-width: 1620px) {
  .container {
    padding: 0;
  }
}

.containerFluid {
  width: 100%;
  padding-right: $gutter-width;
  padding-left: $gutter-width;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$gutter-width;
  margin-left: -$gutter-width;

  &.columnDirection {
    flex-direction: column;
  }
}

.noWrap {
  flex-wrap: nowrap;
}

.h100 {
  height: 100% !important;
}

.noGutters {
  margin-right: 0;
  margin-left: 0;
}

.noGutters > .col,
.noGutters > [class*="col"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.colAuto, .colSm1, .colSm2, .colSm3, .colSm4, .colSm5, .colSm6, .colSm7, .colSm8, .colSm9, .colSm10, .colSm11, .colSm12, .col-sm,
.colMd1, .colMd2, .colMd3, .colMd4, .colMd5, .colMd6, .colMd7, .colMd8, .colMd9, .colMd10, .colMd11, .colMd12, .col-md,
.colLg1, .colLg2, .colLg3, .colLg4, .colLg5, .colLg6, .colLg7, .colLg8, .colLg9, .colLg10, .colLg11, .colLg12, .col-lg,
.colXl1, .colXl2, .colXl3, .colXl4, .colXl5, .colXl6, .colXl7, .colXl8, .colXl9, .colXl10, .colXl11, .colXl12, .col-xl {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: $gutter-width;
  padding-left: $gutter-width;
}

.col {
  display: flex;
  flex-basis: 0;
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-width: 100%;
  box-sizing: border-box;
  &.columnDirection {
    flex-direction: column;
  }
}

.colAuto {
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.colJustified {
  -ms-flex: 1 1 !important;
  flex: 1 1 !important;
  width: 100%
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .colXlAuto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .colXl1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .colXl2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .colXl3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .colXl4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .colXl5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .colXl6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .colXl7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .colXl8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .colXl9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .colXl10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .colXl11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .colXl12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .colMdAuto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .colMd1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .colMd2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .colMd3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .colMd4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .colMd5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .colMd6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .colMd7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .colMd8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .colMd9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .colMd10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .colMd11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .colMd12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

.gutterField {
  position: relative;
  width: 100%;
  margin-bottom: $gutter-width;
}
