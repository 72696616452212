input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input::-ms-clear,
input::-ms-reveal {
    display: none;
}

.select .form-control {
    cursor: pointer;
}

.corner {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.corner span {
    color: #000;
    font-size: 14px;
}

.corner img {
    transition: .3s ease;
    transform: rotate(90deg);
    margin-left: 10px;
}
.corner.open img {
    transform: rotate(-90deg);
}

.form-group{
    font-family: -apple-system,BlinkMacSystemFont,"Source Sans Regular","Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif!important;
}

.form-group.select .form-control {
    cursor: pointer;
}

.form-group.select.attach .select-dropdown.lang-list {
    top: 50px;
    width: 120px !important;
    max-width: 120px !important;
    min-width: 120px !important;
    overflow: hidden;
}

.form-group.select.attach {
    margin-right: 24px;
}

.tooltip-info::after {
    width: -moz-max-content;
    width: max-content;
    max-width: 180px;
    font-size: 12px;
    top: -10px;
}

.copy-icon {
    position: relative;
    top: 1px;
    margin: 0 5px;
}

.page-wrapped .preloader-container {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
}

.page-wrapped.is-loading {
    opacity: .35;
}

.sketch-picker {
    position: relative;
    z-index: 1800;
    width: calc(100% - 20px) !important;
}

div[style="position: relative; height: 10px; margin-top: 4px; overflow: hidden;"],
div[style="width: 24px; height: 24px; position: relative; margin-top: 4px; margin-left: 4px; border-radius: 3px;"]{
    display: none !important;
}

.sketch-picker > div:first-child {
    padding-bottom: 50% !important;
}

.sketch-picker .flexbox-fix input{
    width: 100%!important;
}

.sketch-picker .flexbox-fix:nth-child(3) {
    padding-bottom: 6px;
}

.sketch-picker .flexbox-fix:nth-child(4) {
    display: none!important;
}

.form-group label {
    color: #000
}

.select.form-group:hover .form-control {
    border-color: #afb0b3;
}

.form-group.non-empty label {
    color: #9d9ea0;
}

.multi-select-item:last-child div {
    box-shadow: none;
}

label.fill {
    color: #9d9ea0;
}

.api-code textarea {
    line-height: 1.4;
    height: 125px !important;
}

.backer {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: calc(100% + 2px);
    transition: .2s ease;
    opacity: 0;
    z-index: 1;
}

/*.arrow-button {*/
/*    top: 0!important;*/
/*}*/

.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    opacity: 0;
    outline: none;
    transition: .2s ease;
    transform: scale(.9);
}

.modal-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    transition: .37s ease;
    background: rgba(0,0,0,.7);
}

.modal.show {
    transform: scale(1);
    opacity: 1;
}

.modal-backdrop.show {
    opacity: 1;
}

.facebook {
    fill: #3c88f7;
    fill-rule: evenodd;
}

.whatsapp {
    fill: #5cb552;
}

.whatsapp, .whatsapp-2 {
    fill-rule: evenodd;
}

.whatsapp-2 {
    fill: #fff;
}

.telegram {
    fill: #59a6df;
}

.telegram-2 {
    fill: #e1e2e6;
}

.telegram-2, .telegram-3 {
    fill-rule: evenodd;
}

.telegram-3 {
    fill: #fff;
}

.viber {
    fill: #7b64d8;
}

.viber, .viber-2 {
    fill-rule: evenodd;
}

.viber-2 {
    fill: #fff;
}

.vk {
    fill: #5591d7;
}

.vk-2 {
    fill: #fff;
    fill-rule: evenodd;
}

.cls-1 {
    fill: #acb1bf;
}

.cls-2 {
    fill: #dadde6;
    fill-rule: evenodd;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#blinger-launcher {
    font-family: -apple-system, BlinkMacSystemFont, "Source Sans Regular",
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
    width: 122px;
    height: 122px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9999999;
}

#blinger-channels {
    position: absolute;
    display: flex;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 3px 8px 0 rgba(32, 35, 38, 0.05),
    0 10px 20px 0 rgba(32, 35, 38, 0.1);
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    overflow: hidden;
    border-top: 4px solid #d4d5d9;
}

/*remove scrollbars*/
#blinger-channels::-webkit-scrollbar {
    width: 0 !important;
}

#blinger-channels {
    overflow: -moz-scrollbars-none;
}

#blinger-channels {
    -ms-overflow-style: none;
}

.channels-wrapper-scroll {
    scrollbar-width: none;
}

 .channels-wrapper-scroll {
     -ms-overflow-style: none;
 }

 .channels-wrapper-scroll {
     overflow: -moz-scrollbars-none;
 }

.channels-wrapper-scroll::-webkit-scrollbar {
    display: none!important;
}

#blinger-invitation {
    z-index: 2147483000;
    position: fixed;
    bottom: 31px;
    right: 31px;
    width: 376px;
    height: 178px;
    display: none;
    box-shadow: 0 3px 8px 0 rgba(32, 35, 38, 0.05),
    0 10px 20px 0 rgba(32, 35, 38, 0.1);
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-top: 4px solid #d4d5d9;

    animation-name: transformIn;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: bottom right;
}


#blinger-invitation.invitation-show {
    display: block;
}

#blinger-invitation.invitation-hide .invitation-wrapper {
    animation-name: opacity;
    animation-duration: 0.1s;
    animation-fill-mode: both;
    transform-origin: bottom right;
}

#blinger-invitation.invitation-hide {
    animation-name: transformInOff;
    animation-duration: 0.2s;
    animation-fill-mode: both;
    transform-origin: bottom right;
    z-index: -1;
}

.invitation-agent {
    z-index: 2147483000;
    position: fixed;
    bottom: 31px;
    right: 31px;
    width: 376px;
    /* height: 148px !important; */
    box-shadow: 0 3px 8px 0 rgba(32, 35, 38, 0.05),
    0 10px 20px 0 rgba(32, 35, 38, 0.1);
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-top: 4px solid #37409f;
    min-height: 148px !important;
    height: auto !important;
}

.channels.channels-show {
    animation-name: fadeUp;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: bottom;
    display: flex !important;
}

.messengers-only {
    max-height: 624px !important;
}

.messengers-only .channels-wrapper-chat {
    margin-bottom: 32px !important;
    border-bottom: 1px solid #e8eaed;
}

/* Common Styles End*/


/* Launcher */

.launcher {
    text-align: right;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.launcher-button-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 60px;
    width: 60px;
    margin: auto;
}

.launcher-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 47%;
    -webkit-border-radius: 47%;
    -moz-border-radius: 47%;
    /*border: 4px solid #37409f;*/
    left: -1px;
    position: relative;
    box-shadow: 0 3px 8px 0 rgba(32, 35, 38, 0.05), 0 10px 20px 0 rgba(32, 35, 38, 0.1);
    transition: all .2s ease;
    outline: 0;
    background-color: #fff;
    cursor: pointer;
}


.launcher-button.launcher-transform-on {
    animation-name: transformInWidget;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: bottom right;
    border: none;
}

.launcher-button.launcher-transform-off {
    animation-name: transformInWidgetOff;
    animation-duration: 0.2s;
    animation-delay: 0.1s;
    animation-fill-mode: both;
    transform-origin: bottom right;
    border: none;
    display: flex !important;
}

.launcher-button.launcher-transform-on .widget-menu-icon {
    display: none;
}

.launcher-button.launcher-transform-off .widget-menu-icon {
    display: block;
}

.launcher-button {
    animation: showIn .4s cubic-bezier(.62, .28, .23, .99);
}

.launcher-button-open svg .widget-menu {
    animation-name: rotate-menu;
    animation-duration: .3s;
    animation-fill-mode: both;
    transform-origin: bottom;
    display: block !important;
}

.launcher-button .widget-menu-icon {
    transition: all .2s ease;
    position: absolute;
}

.launcher-button svg .launcher-circle {
    transition: all .2s ease;
}

.launcher-button:active > svg .launcher-circle {
    fill: #37409f !important;
}

.launcher-button.launcher-button-open > svg .launcher-circle {
    fill: #37409f !important;
}

.widget-close-icon {
    display: none;
    position: absolute;
    width: 12px;
    height: 12px;
    transform: scale(1.3, 1.3);
    transition: transform .2s ease;
}

.launcher-inner {
    position: absolute;
    width: 52px;
    transition: all .2s ease;
}

.launcher-button:hover > .launcher-inner {
    transform: scale(.92);
}

.launcher-button:active > .launcher-inner {
    transform: scale(0);
}

.launcher-button.launcher-button-open > .launcher-inner {
    transform: scale(0);
}

.launcher-button-open .widget-close-icon {
    display: block;
}

.launcher-button-open .widget-menu-icon {
    display: none;
}

.launcher-button.launcher-button-open:hover > .widget-close-icon {
    transform: scale(1.1);
}

.launcher-button-active .launcher-button {
    border: none !important;
    background-color: #37409f;
    cursor: pointer;
}

.launcher-button-active a {
    display: none !important;
}

.launcher-button-active-container {
    display: none;
    width: 100%;
    height: 100%;
}

.launcher-button-active .launcher-button-active-container {
    display: flex;
}

.launcher-button-wrapper.launcher-button-active .launcher-button:hover > .agent-avatar {
    transform: scale(.88);
}

.launcher-button-wrapper.launcher-button-active .launcher-button.launcher-button-open .agent-avatar {
    display: none;
}


.launcher-button-wrapper.launcher-button-active .launcher-button.launcher-button-open .agent-avatar {
    display: none;
}

.launcher-button-wrapper.launcher-button-active .launcher-button .agent-avatar {
    display: block;
}

.launcher-button-active .agent-avatar {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 47%;
    -webkit-border-radius: 47%;
    -moz-border-radius: 47%;
    z-index: 10;
    transition: all .2s ease;
}

.launcher-button-wrapper .launcher-button .agent-avatar {
    display: none;
}

.launcher-counter {
    position: absolute;
    right: -6px;
    top: -6px;
    width: 24px;
    height: 24px;
    background-color: #ce2549;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 11;
}

.launcher-button-wrapper.launcher-button-active .launcher-button.launcher-button-open .launcher-counter {
    display: none;
}

.launcher-button-wrapper.launcher-button-active .launcher-button .launcher-counter {
    display: flex;
}

.launcher-button-wrapper .launcher-button .launcher-counter {
    display: none;
}
/* Launcher End */


/* Channels Wrapper */
.channels-wrapper {
    width: 100%;
    min-width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
}

.channels-wrapper-scroll {
    padding-top: 0;
    height: 100%;
    overflow: scroll;
}

.channels-wrapper-scroll {
    scrollbar-width: none;
}

 .channels-wrapper-scroll {
     -ms-overflow-style: none;
 }

 .channels-wrapper-scroll {
     overflow: -moz-scrollbars-none;
 }

.channels-wrapper-container {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    padding-top: 0;
    height: auto;
}

/*remove scrollbars*/
.channels-wrapper-container::-webkit-scrollbar {
    width: 0 !important;
}

.channels-wrapper-container {
    overflow: -moz-scrollbars-none;
}

.channels-wrapper-container {
    -ms-overflow-style: none;
}

/*wrapper header*/
.channels-wrapper-header {
    width: 100%;
    height: 96px;
    padding: 32px 40px 16px 40px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999999999;
    transition: all .2s ease;
    border-radius: 16px 16px 0 0;
    -webkit-border-radius: 16px 16px 0 0;
    -moz-border-radius: 16px 16px 0 0;
}

.channels-wrapper-header-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
}

.channels-wrapper-header-info-logo {
    width: 48px;
    height: 48px;
    background-color: #e1e2e5;
    border-radius: 47%;
    overflow: hidden;
}

.channels-wrapper-header-info-logo.logo-complete {
    background: none;
}

.channels-wrapper-header-info-col {
    cursor: default;
    height: 36px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-left: 10px;
}

.channels-wrapper-header-info-title {
    font-size: 14px;
    color: #000;
    font-weight: 600;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    min-height: 18px;
}

.channels-wrapper-header-info-status {
    font-size: 12px;
    line-height: 1.2;
    margin-top: 5px;
    color: #969799;
    position: relative;
}

.channels-status-online:after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    right: -14px;
    top: 5px;
    background-color: #82b167;
}

.channels-status-offline:after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    right: -14px;
    top: 4.4px;
    background-color: #c02546;
}

.channels-wrapper-header-info-logo img {
    width: 100%;
    height: 100%;
}

.channels-wrapper-header-avatar img {
    width: 40px;
    height: 40px;
}

.channels-wrapper-header-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    transition: .3s ease;
    opacity: 0;
}

.channels-wrapper-header-avatar.show-agent {
    opacity: 1;
}

/*chat block*/
.channels-wrapper-chat {
    padding: 16px 20px 32px 20px;
}

.channels-wrapper-chat.channels-wrapper-chat-messengers-only {
    padding-bottom: 32px;
    border-bottom: 1px solid #e8eaed;
}

.channels-wrapper-chat-message-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0 20px;
}

.channels-wrapper-chat-hello {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    max-width: 100%;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.channels-wrapper-chat-message {
    max-width: 100%;
    font-size: 16px;
    color: #000;
    margin-top: 12px;
    line-height: 22px;
    overflow-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.channels-wrapper-chat-button {
    width: 100%;
    height: 64px;
    background-color: #fff;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    border-top: 1px solid #e1e3e6;
    border-bottom: 1px solid #e1e3e6;
    transition: all 0.2s ease;
    user-select: none;
    pointer-events: none;
    font-size: 16px;
}

.channels-wrapper-chat-button.complete {
    pointer-events: all;
    position: sticky;
    top: -1px;
    z-index: 100;
}

.channels-wrapper-chat-button > div {
    position: relative;
    z-index: 10;
}

.channels-wrapper-chat-button-arrow {
    width: 32px;
    height: 32px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    background-color: #d4d5d9;
}

.channels-wrapper-chat-button-agent:hover {
    background-color: #f2f4f7;
    border-top: 1px solid #f2f4f7;
    border-bottom: 1px solid #f2f4f7;
}

.channels-wrapper-scroll.scroll .channels-wrapper-chat-button {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 0;
    background-color: #fff;
    z-index: 1000;
    border-top: 0;
}

.channels-wrapper-scroll.scroll .channels-wrapper-messengers {
    margin-top: 96px;
}

/*messengers block*/
.channels-wrapper-messengers {
    margin: 32px 0 44px 0;
    padding: 0 32px;
}

.channels-wrapper-messengers.false img {
    filter: grayscale(100%);
    opacity: .4;
}

.channels-wrapper-messengers.false .channels-wrapper-messenger {
   pointer-events: none;
}

.channels-wrapper-messengers-title {
    font-size: 16px;
    color: #000;
    font-weight: 600;
}

.channels-wrapper-messengers-text {
    margin-top: 8px;
    font-size: 14px;
    color: #646566;
    line-height: 20px;
    width: 100%;
}

.channels-wrapper-messengers-block {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.channels-wrapper-messenger {
    /*width: 88px;*/
    height: 88px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #e1e3e6;
    cursor: pointer;
    transition: all 0.2s ease;
}

.channels-wrapper-messenger img {
    max-height: 36px;
    width: 36px;
}

.channels-wrapper-messenger.one-col.customizer:first-child {
    height: 64px;
    width: 100%;
    flex-direction: row;
}

.channels-wrapper-messenger.one-col.customizer .channels-wrapper-messenger-title {
    padding-left: 10px;
    margin-top: 0;
}

.channels-wrapper-messenger.two-col.customizer:first-child {
    width: 50%;
    height: 88px;
    flex-direction: column;
}


.channels-wrapper-messenger.two-col.customizer {
    width: calc(50% - 8px);
    height: 88px;
    flex-direction: column;
}

.channels-wrapper-messenger.three-col.customizer:first-child {
    width: 88px;
    height: 88px;
    flex-direction: column;
}

.channels-wrapper-messenger.three-col.customizer {
    width: 88px;
    height: 88px;
    flex-direction: column;
}

.channels-wrapper-messenger.four-col.customizer:first-child {
    width: 50%;
    height: 88px;
    flex-direction: column;
}

.channels-wrapper-messenger.four-col.customizer {
    width: calc(50% - 8px) !important;
    height: 88px;
    flex-direction: column;
}

.channels-wrapper-messenger.five-col.customizer:nth-child(1),
.channels-wrapper-messenger.five-col.customizer:nth-child(2) {
    width: calc(50% - 8px) !important;
    height: 88px;
    flex-direction: column;
}

.channels-wrapper-messenger.five-col.customizer:nth-child(3),
.channels-wrapper-messenger.five-col.customizer:nth-child(4),
.channels-wrapper-messenger.five-col.customizer:nth-child(5) {
    width: 88px !important;
    height: 88px;
    flex-direction: column;
}

.channels-wrapper-messenger.seven-col.customizer:nth-child(4),
.channels-wrapper-messenger.seven-col.customizer:nth-child(5),
.channels-wrapper-messenger.seven-col.customizer:nth-child(6),
.channels-wrapper-messenger.seven-col.customizer:nth-child(7) {
    width: calc(50% - 8px) !important;
    height: 88px;
    flex-direction: column;
}

.channels-wrapper-messenger.eight-col.customizer:nth-child(7),
.channels-wrapper-messenger.eight-col.customizer:nth-child(8) {
    width: calc(50% - 8px) !important;
    height: 88px;
    flex-direction: column;
}

.channels-wrapper-messenger.ten-col.customizer:nth-child(7),
.channels-wrapper-messenger.ten-col.customizer:nth-child(8),
.channels-wrapper-messenger.ten-col.customizer:nth-child(9),
.channels-wrapper-messenger.ten-col.customizer:nth-child(10) {
    width: calc(50% - 8px) !important;
    height: 88px;
    flex-direction: column;
}

.channels-wrapper-messenger.eleven-col.customizer:nth-child(10),
.channels-wrapper-messenger.eleven-col.customizer:nth-child(11) {
    width: calc(50% - 8px) !important;
    height: 88px;
    flex-direction: column;
}

.channels-wrapper-messenger.thirteen-col.customizer:nth-child(10),
.channels-wrapper-messenger.thirteen-col.customizer:nth-child(11),
.channels-wrapper-messenger.thirteen-col.customizer:nth-child(12),
.channels-wrapper-messenger.thirteen-col.customizer:nth-child(13) {
    width: calc(50% - 8px) !important;
    height: 88px;
    flex-direction: column;
}

.channels-wrapper-messenger.two-col.customizer:first-child .channels-wrapper-messenger-title {
    padding-left: 0;
}

.channels-wrapper-messengers .channels-wrapper-messenger.customizer svg {
    filter: none
}



.column-1, .column-2, .column-3 {
    margin: 8px;
}

.column-1 {
    width: 100%;
}

.column-2 {
    width: 140px;
}

.column-3 {
    width: 88px;
}

.column-1 .channels-wrapper-messenger {
    height: 64px;
    flex-direction: row;
}

.column-1 .channels-wrapper-messenger .channels-wrapper-messenger-title {
    margin-top: 0;
    margin-left: 8px;
}

.channels-wrapper-messengers-text-block {
    cursor: default;
    padding: 0 8px;
}

.channels-change {
    position: relative;
}

.channels-wrapper-messenger.channels-change {
    padding-left: 15px;
    width: 100%;
    height: 60px;
    flex-direction: row;
    justify-content: flex-start;
}
.channels-wrapper-messenger.channels-change .channels-wrapper-messenger-title {
    padding-left: 10px;
    font-size: 14px;
    margin-top: 0;
    font-weight: 500;
}

.channels-wrapper-messenger.channels-change:last-child {
    margin-bottom: 30px;
}

.channels-change-image {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
}

.channels-change-image path {
    fill: #555;
}

/* svg hover example 1 */

/*!*svg hover effects*!*/
/*.channels-wrapper-messenger:hover > .channels-wrapper-messenger-title {*/
/*color: #fff;*/
/*}*/

/*!*hover facebook*!*/
/*.channels-wrapper-messenger-facebook:hover {*/
/*background-color: #3c88f7;*/
/*border: 1px solid #3c88f7;*/
/*}*/

/*.channels-wrapper-messenger-facebook:hover > svg .facebook {*/
/*fill: #fff;*/
/*}*/

/*!*hover whatsapp*!*/
/*.channels-wrapper-messenger-whatsapp:hover {*/
/*background-color: #5cb552;*/
/*border: 1px solid #5cb552;*/
/*}*/

/*.channels-wrapper-messenger-whatsapp:hover > svg .whatsapp {*/
/*fill: #fff;*/
/*}*/

/*.channels-wrapper-messenger-whatsapp:hover > svg .whatsapp-2 {*/
/*fill: #5cb552;*/
/*}*/

/*!*hover telegram*!*/
/*.channels-wrapper-messenger-telegram:hover {*/
/*background-color: #59a6df;*/
/*border: 1px solid #59a6df;*/
/*}*/

/*!*hover viber*!*/
/*.channels-wrapper-messenger-viber:hover {*/
/*background-color: #7b64d8;*/
/*border: 1px solid #7b64d8;*/
/*}*/

/*.channels-wrapper-messenger-viber:hover > svg .viber {*/
/*fill: #fff;*/
/*}*/

/*.channels-wrapper-messenger-viber:hover > svg .viber-2 {*/
/*fill: #7b64d8;*/
/*}*/

/*!*hover vk*!*/
/*.channels-wrapper-messenger-vk:hover {*/
/*background-color: #5591d7;*/
/*border: 1px solid #5591d7;*/
/*}*/

/*.channels-wrapper-messenger-vk:hover > svg .vk {*/
/*fill: #fff;*/
/*}*/

/*.channels-wrapper-messenger-vk:hover > svg .vk-2 {*/
/*fill: #5591d7;*/
/*}*/

/*!*hover email*!*/
/*.channels-wrapper-messenger-email:hover {*/
/*background-color: #acb1bf;*/
/*border: 1px solid #acb1bf;*/
/*}*/

/*.channels-wrapper-messenger-email:hover > svg .email {*/
/*fill: #dadde6;*/
/*}*/

/*.channels-wrapper-messenger-email:hover > svg .email-2 {*/
/*fill: #fff;*/
/*}*/

/* svg hover example 2 */

.channels-wrapper-messenger:hover {
    /*box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.10);*/
    /*border: 2px solid #e1e3e6;*/
}

.channels-wrapper-chat-button-title {
    cursor: default;
}

.channels-wrapper-messenger-title {
    color: #000;
    font-size: 12px;
    margin-top: 6px;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.channels-wrapper-chat-button .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    cursor: default;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}

.channels-wrapper-chat-button:hover .bg {
    opacity: 1;
    z-index: -1;
}

.channels-wrapper-chat-button.White:hover {
    color: #fff!important;
}

.channels-wrapper-chat-button.Black:hover {
    color: #000!important;
}

.channels-wrapper-messengers-block a{
    text-decoration: none;
    transition: all .2s ease;
}

.channels-wrapper-messengers-block a:hover{
    border: 2px solid #e1e3e6;
}

/*powered by block*/
.powered-by {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 32px;
    border-top: 1px solid #f6f7f9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    background-color: #fff;
    border-radius: 0 0 16px 16px;
    -webkit-border-radius: 0 0 16px 16px;
    -moz-border-radius: 0 0 16px 16px;
    transition: .3s;
    opacity: 1;
    pointer-events: none;
}

.powered-by.show {
    animation-name: fadeInUp;
}

.powered-by span {
    color: #969799;
}

.powered-by a {
    color: #000000;
    text-decoration: none;
}

.powered-by a:hover {
    text-decoration: underline;
}

.powered-by a img {
    margin: 0 4px;
    position: relative;
    top: 2px;
}

.agent-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
}

.agent-container img {
    width: 40px;
    height: 40px;
    border-radius: 47%;
}

.agent-container-text {
    height: 36px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-left: 10px;
}

.agent-container-text-name {
    font-size: 14px;
    color: #000;
    max-width: 240px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.agent-container-text-message {
    font-size: 12px;
    color: #969799;
    max-width: 240px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.agent-container-info {
    display: flex;
}

.counter {
    width: 24px;
    height: 24px;
    background-color: #ce2549;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

/*animation*/
.channels-wrapper-header {
    animation-name: fadeInUp;
    animation-duration: 0.4s;
    animation-delay: 0.1s;
    animation-fill-mode: both;
}

.channels-wrapper-chat {
    animation-name: fadeInUp;
    animation-duration: 0.4s;
    animation-delay: 0.2s;
    animation-fill-mode: both;
}

.channels-wrapper-chat-button {
    animation-name: fadeInUp;
    animation-duration: 0.4s;
    animation-delay: 0.3s;
    animation-fill-mode: both;
}

.channels-wrapper-messengers {
    animation-name: fadeInUp;
    animation-duration: 0.4s;
    animation-delay: 0.4s;
    animation-fill-mode: both;
}

.powered-by {
    animation-name: none;
    animation-duration: 0.4s;
    animation-delay: 0.6s;
    animation-fill-mode: both;
}

.chat-wrapper {
    min-width: 100%;
    transition: transform 0.4s ease 0.2s, opacity 0.3s ease;
    transform: translateX(0px);
    opacity: 0;
    overflow: hidden;
}

.chat-wrapper.chat-wrapper-active {
    transition: transform 0.2s ease, opacity 0.2s ease 0.2s;
    transform: translateX(-376px);
    opacity: 1;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
}

.channels-wrapper {
    transition: transform 0.2s ease, opacity 0.2s ease 0.2s;
    transform: translateX(0px);
    opacity: 1;
}

.channels-wrapper.channels-wrapper-chat-show {
    transition: transform 0.2s ease 0.2s, opacity 0.3s ease;
    transform: translateX(-376px);
    opacity: 0;
}

.chat-wrapper {
    display: none;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.chat-wrapper.chat-wrapper-active {
    display: flex;
}

.channels-wrapper.channels-wrapper-chat-show .channels-wrapper-header {
    display: none;
}

.channels-wrapper.channels-wrapper-chat-show .channels-wrapper-container {
    display: none;
}

.chat-wrapper .chat-wrapper-header {
    padding: 16px 0;
    height: 80px;
    min-height: 80px;
    border-bottom: 1px solid #e1e2e5;
    width: 100%;
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    z-index: 99999999999;

    animation-name: fadeInRight;
    animation-duration: 0.4s;
    animation-delay: 0.1s;
    animation-fill-mode: both;
}

.arrow-chat-button {
    width: 48px;
    height: 80px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
}

.chat-option-dropdown {
    width: 246px;
    position: absolute;
    right: 40px;
    top: 8px;
    background-color: #fff;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 11.04px 0.96px rgba(27, 30, 38, 0.15),
    0 0 1px 0 rgba(27, 30, 38, 0.03);
}

/* chat option dropdown animation */
.chat-option-dropdown {
    transition: all 0.2s ease;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transform: scale(1, 0);
    transform-origin: top right;
}

/*.chat-option-dropdown-active {*/
/*transition: all 0.2s ease;*/
/*z-index: 9999;*/
/*opacity: 1;*/
/*visibility: visible;*/
/*transform: scale(1, 1);*/
/*}*/

.chat-option-dropdown ul {
    transition: all 0.1s ease;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
}

.chat-option-dropdown-active ul {
    transition: all 0.2s ease 0.3s;
    opacity: 1;
    z-index: 9999;
    visibility: visible;
}

/* chat option dropdown animation end */

/* chat emogy dropdown animation */
.chat-message-emogy {
    transition: all 0.2s ease;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transform: scale(1, 0);
    transform-origin: bottom right;
}

.chat-message-emogy-active {
    transition: all 0.2s ease;
    z-index: 9999;
    opacity: 1;
    visibility: visible;
    transform: scale(1, 1);
}

/* chat emogy dropdown animation */

.chat-option-dropdown ul {
    list-style: none;
}

.chat-option-dropdown ul li {
    height: 36px;
    padding: 0 16px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.2s ease;
}

.chat-option-dropdown ul li:hover {
    background-color: #edeff2;
}

/*chat container*/
.chat-message-input {
    width: 100%;
    /*min-height: 64px;*/
    padding-top: 2px;
    /* height: 10%; */
    /* max-height: 300px; */
    /* height: auto; */
    position: relative;
    border-top: 1px solid #edeff2;
    display: flex;
    flex-wrap: nowrap;
    z-index: 9999;
    animation-name: fadeInUp;
    animation-duration: 0.4s;
    animation-delay: 0.5s;
    animation-fill-mode: both;
    border-radius: 0 0 12px 12px;
    /* overflow: hidden; */
    box-sizing: border-box;
    background: #fff;
    pointer-events: none;
}

.chat-message-input textarea {
    width: 100%;
    min-height: 20px;
    height: 20px;
    max-height: 170px;
    border: none;
    resize: none;
    outline: none;
    padding: 0 80px 0 20px;
    margin: 20px 0;
    font-size: 14px;
}

.chat-message-input-chat-only {
    display: flex;
    flex-direction: column;
    border-top: none;
}


.chat-message-input-chat-only .powered-by {
    position: relative;
    border-bottom: 1px solid #edeff2;
    border-top: none;
    border-radius: 0;
    height: 48px;
    min-height: 48px;
}

.channels-wrapper-chat-only {
    padding-bottom: 32px;
    border-bottom: 1px solid #edeff2;
}

.chat-message-input-chat-only-container .chat-message-input-buttons {
    padding-top: 69px;
}

.chat-message-input-chat-only .chat-message-send-button {
    top: 66px;
}

.chat-message-input input {
    width: 302px;
    height: 100%;
    border: none;
    resize: none;
    outline: none;
    padding: 22px 20px;
    font-size: 14px;
    border-radius: 0 0 12px 12px;
}

.chat-message-input textarea::placeholder {
    color: #000;
    font-family: -apple-system, BlinkMacSystemFont, "Source Sans Regular",
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.chat-message-input input::placeholder {
    color: #000;
    font-family: -apple-system, BlinkMacSystemFont, "Source Sans Regular",
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.chat-message-input textarea:-ms-input-placeholder {
    color: #000;
    font-family: -apple-system, BlinkMacSystemFont, "Source Sans Regular",
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.chat-message-input input:-ms-input-placeholder {
    color: #000;
    font-family: -apple-system, BlinkMacSystemFont, "Source Sans Regular",
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.chat-message-input textarea::-ms-input-placeholder {
    color: #000;
    font-family: -apple-system, BlinkMacSystemFont, "Source Sans Regular",
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.chat-message-input input::-ms-input-placeholder {
    color: #000;
    font-family: -apple-system, BlinkMacSystemFont, "Source Sans Regular",
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.chat-container {
    width: 100%;
    height: 100%;
    /*max-height: 400px;*/
    min-height: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 20px;

    animation-name: fadeInUp;
    animation-duration: 0.4s;
    animation-delay: 0.2s;
    animation-fill-mode: both;
}

.right-message {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.message-container {
    display: inline-block;
    position: relative;
    padding: 16px 20px;
    margin: 40px 0 0 0;
    font-size: 14px;
    border-radius: 10px;
    max-width: 260px;
    min-height: 48px;
    cursor: default;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
}

.right-message .message-container {
    background-color: #37409f;
    color: #fff;
}

.left-message .message-container {
    background-color: #fff;
    color: #000;
    border: 1px solid #d9dbde;
}

.left-message {
    display: flex;
    align-items: flex-end;
}

.message-avatar {
    width: 32px;
    height: 32px;
    border: 1px solid #d9dbde;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 10px;
}

.chat-option {
    width: 42px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    pointer-events: none;
}

.chat-option.active > img {
    filter: brightness(10%);
}

.chat-option.active .chat-option-dropdown {
    transition: all 0.2s ease;
    z-index: 9999;
    opacity: 1;
    visibility: visible;
    transform: scale(1, 1);
}

.chat-option.active .chat-option-dropdown ul {
    transition: all 0.2s ease 0.3s;
    opacity: 1;
    z-index: 9999;
    visibility: visible;
}

.message-container-status {
    position: absolute;
    bottom: -20px;
    font-size: 11px;
    color: #969799;
}

.right-message .message-container-status {
    right: 0;
}

.left-message .message-container-status {
    left: 0;
}

.chat-wrapper-active.chat-wrapper-active-noconfirm .chat-message-input {
    display: none !important;
}

.chat-container input {
    transition: all 0.2s ease;
}

.chat-container input:hover {
    border: 1px solid #afb0b3;
}

.chat-container input:focus {
    border: 1px solid #afb0b3;
    box-shadow: 0 0 0 2px #e1e3e6;
}

.form-input-container-disabled input {
    pointer-events: none;
}

.form-input-container-disabled input:hover {
    border: 1px solid #d9dbde !important;
}

.form-input-container-disabled input:focus {
    border: 1px solid #d9dbde !important;
    box-shadow: none !important;
}

.chat-container textarea {
    transition: all 0.2s ease;
}

.chat-container textarea:hover {
    border: 1px solid #afb0b3;
}

.chat-container textarea:focus {
    border: 1px solid #afb0b3;
    box-shadow: 0 0 0 2px #e1e3e6;
}

/*chat tooltip*/
.message-container:hover > .message-container-tooltip {
    opacity: 1;
    visibility: visible;
    z-index: 1;
}

.message-container-tooltip {
    position: absolute;
    bottom: -20px;
    right: 0;
    border-radius: 5px;
    background-color: #f1f1f4;
    border: 1px solid #d9dade;
    padding: 5px 10px;
    font-size: 10px;
    color: #000;
    box-shadow: 0 5px 5px 0 rgba(37, 40, 50, 0.05);
    transition: all 0.2s ease;
    opacity: 0;
    visibility: hidden;
    z-index: 0;
}

.chat-message-input-buttons {
    position: absolute;
    right: 0;
    top: 0;
    width: 74px;
    /*height: 100%;*/
    padding-right: 20px;
    padding-top: 20px;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-end;
    /*margin-top: 20px;*/
}

.chat-message-input-buttons-emogy {
    margin-right: 16px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.chat-message-input-buttons-emogy.active > img {
    filter: brightness(10%);
}

.chat-message-input-buttons-emogy img {
    transition: all 0.2s ease;
}

.chat-message-input-buttons-emogy:hover > img {
    filter: brightness(10%);
}

.chat-message-input-buttons-add img {
    transition: all 0.2s ease;
}

.chat-message-input-buttons-add:hover > img {
    filter: brightness(10%);
}

.chat-option img {
    transition: all 0.2s ease;
}

.chat-option:hover > img {
    filter: brightness(10%);
}

.arrow-chat-button img {
    transition: all 0.2s ease;
}

.arrow-chat-button:hover > img {
    filter: brightness(10%);
}

.chat-message-input-buttons-emogy.active ~ .chat-message-emogy {
    transition: all 0.2s ease;
    z-index: 9999;
    opacity: 1;
    visibility: visible;
    transform: scale(1, 1);
}

.chat-message-input-buttons-add {
    width: 17px;
    height: 17px;
    cursor: pointer;
}

.chat-container .powered-by {
    position: relative;
    top: 0;
    width: 336px;
    height: 48px;
    min-height: 48px;
    margin-top: 40px;
    background-color: transparent;
    border-top: none;
    z-index: 9999;
    animation-delay: 0.5s;
}

.left-messages-scope .left-message:first-child {
    margin-top: 40px;
}

.left-messages-scope .message-container {
    margin-top: 4px;
}

.left-messages-scope .message-avatar {
    opacity: 0;
}

.left-messages-scope .message-container-status {
    opacity: 0;
}

.left-messages-scope .left-message:last-child > .message-avatar {
    opacity: 1;
}

.left-messages-scope
.left-message:last-child
> .message-container
.message-container-status {
    opacity: 1;
}

.left-messages-scope-agent .message-container-status {
    display: none;
}

.left-messages-scope
.left-message:last-child
> .message-container-form
.message-container-status {
    opacity: 1;
}

/* CHAT FORM */
.message-container-form {
    width: 260px;
    background-color: #edeff2;
    color: #000;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    border-radius: 10px;
    padding: 20px;
    pointer-events: none;
}

.message-container-form form {
    display: flex;
    flex-direction: column;
}

.input {
    border-radius: 5px;
    width: 100%;
    height: 34px;
    outline: none;
    border: 1px solid #d9dbde;
    font-size: 12px;
    color: #000;
    padding: 0 10px;
}

.input::placeholder {
    color: #000;
}

.form-input-container > .input {
    margin-bottom: 8px;
}

.form-input-container {
    width: 100%;
    position: relative;
}


.form-input-container textarea {
    border-radius: 5px;
    width: 100%;
    height: 100px;
    outline: none;
    border: 1px solid #d9dbde;
    font-size: 12px;
    color: #000;
    padding: 10px;
    resize: none;
    margin-bottom: 8px;
}

.form-input-container textarea::placeholder {
    color: #000;
}

/*chat input validation*/
.form-message {
    font-size: 12px;
    color: #000;
    margin-bottom: 8px;
    display: none;
}

.message-container-form.validation-error-status .form-message {
    display: block;
}

.validation-warning-input .input {
    border: 1px solid #caae26;
}

.validation-error-input .input {
    border: 1px solid #d30043;
}

.form-input-container .input-message {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 12px;
    display: none;
}

.validation-warning-input .input-message {
    color: #caae26;
    display: block;
}

.validation-error-input .input-message {
    color: #d30043;
    display: block;
}

/*agree checkbox*/
.agree-checkbox {
    font-size: 12px;
    margin: 12px 0 12px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.agree-checkbox span {
    font-size: 12px;
    margin-left: 4px;
}

.agree-checkbox a {
    text-decoration: underline;
    color: #000;
}

/*custom checkbox*/
.agree-checkbox-container {
    display: flex;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.agree-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.agree-checkbox-checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    border: 1px solid #d9dbde;
    background-color: #fff;
    border-radius: 5px;
    transition: all 0.2s ease;
}

.agree-checkbox-checkmark:hover {
    border: 1px solid #afb0b3;
}

.agree-checkbox-checkmark img {
    display: none;
}

.agree-checkbox-container input:checked ~ .agree-checkbox-checkmark img {
    display: block;
}

/*send button*/
.chat-send-button {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    transition: all .2s ease;
}

.chat-send-button:hover {
    filter: brightness(90%);
}

.chat-send-button {
    background-color: #37409f;
    cursor: pointer;
}

.chat-send-button.disabled {
    background-color: #bebfc2;
    pointer-events: none;
}

.bot-left-message .message-container-bot {
    background-color: #edeff2;
    border: none;
}

.form-input-container .input-status {
    position: absolute;
    top: 8px;
    right: 10px;
}

.message-container-file-image {
    padding: 0;
    background-color: transparent !important;
    border: none !important;
}

.message-container-file-image img {
    width: 100%;
    height: 100% !important;
    max-height: 360px;
    border-radius: 10px;
}

.message-container-file {
    width: 260px;
}

.message-file-size {
    font-size: 12px;
    margin-top: 4px;
}

.right-message .message-file-size {
    color: #aeb1d3;
}

.left-message .message-file-size {
    color: #7e7f80;
}

.message-file-icon {
    cursor: pointer;
}

.message-file-icon img {
    transition: all 0.2s ease;
}

.message-file-icon img:hover {
    filter: brightness(10%);
}

.message-not-delivered {
    background-color: #9b9fcf !important;
}

.message-not-delivered .message-container-tooltip {
    display: none;
}

.right-message {
    position: relative;
}

.right-message .resend-button {
    position: absolute;
    left: -26px;
    top: 0;
    cursor: pointer;
}

.right-message .resend-button img {
    transition: all 0.2s ease;
}

.right-message .resend-button img:hover {
    filter: brightness(10%);
}

.message-file-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.message-type {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 8px;
}

.message-type-point {
    width: 6px;
    height: 6px;
    margin: 2px;
    border-radius: 50%;
    background-color: #e1e3e6;
    position: relative;
}

.message-type-point.first {
    animation: wave 0.8s 0.1s ease-out infinite;
}

.message-type-point.second {
    animation: wave 0.8s 0.2s ease-out infinite;
}

.message-type-point.third {
    animation: wave 0.8s 0.3s ease-out infinite;
}

/*.chat-message-input-buttons {*/
/*position: relative;*/
/*}*/

.chat-message-emogy {
    /*display: none;*/
    position: absolute;
    right: 20px;
    bottom: 36px;
    width: 336px;
    background-color: #fff;
    border-radius: 10px;
    z-index: 9999;
    box-shadow: 0 3px 8px 0 rgba(32, 35, 38, 0.05),
    0 10px 20px 0 rgba(32, 35, 38, 0.1);
}

/*.chat-message-emogy-active {*/
/*display: block;*/
/*}*/

.chat-message-emogy-block {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 250px;
}

.chat-message-emogy-block img {
    width: 100%;
    border-radius: 10px;
}

.chat-message-emogy-search {
    padding: 0 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    border-bottom: 1px solid #e1e2e6;
}

.chat-message-emogy-search input {
    width: 100%;
    height: 100%;
    padding: 8px 12px;
    font-size: 14px;
    outline: none;
    border: none;
}

/*rate conversation*/
.message-rate {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    flex-direction: column;
}

.message-rate .emogy-level {
    margin: 16px 0 40px 0;
}

.emogy-reason-button {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #d9dbde;
    cursor: pointer;
    color: #a3a3a3;
}

.emogy-reason-button {
    transition: color 0.2s ease;
}

.emogy-reason-button:hover {
    color: #37409f;
}

.message-container-rate {
    width: 260px;
    padding: 40px 0 0 0;
}

.message-rates {
    margin: 20px 0 40px 0;
}

.chat-message-send-button {
    display: none;
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    align-items: center;
    justify-content: center;
    background-color: #37409f;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 18px;
    right: 20px;
}

.chat-message-send-button img {
    width: 7px;
}

/*Animations*/
@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 10%, 0);
        transform: translate3d(0, 10%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(10%, 0, 0);
        transform: translate3d(10%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes wave {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(7px);
    }

    100% {
        transform: translateY(0);
    }
}

/* Channels Wrapper End */


/* Invitations */
.invitation-wrapper {
    width: 376px;
    height: 174px;
    box-sizing: border-box;
    position: relative;
    background-color: #fff;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    overflow: hidden;
}

.invitation-wrapper .channels-wrapper-header {
    height: auto !important;
    padding: 20px 20px 12px 20px !important;
    border-radius: 16px 16px 0 0;
    -webkit-border-radius: 16px 16px 0 0;
    -moz-border-radius: 16px 16px 0 0;
}

.invitation-message {
    width: 100%;
    padding: 86px 20px 0 20px;
}

.invitation-message p {
    font-size: 14px;
    color: #000;
}

.invitation-close-button {
    position: absolute;
    right: 0;
    top: 0;
}

.invitation-message {
    animation-name: fadeInUp;
    animation-duration: .4s;
    animation-delay: .6s;
    animation-fill-mode: both;
}

/*invitation agent*/
.invitation-wrapper-agent .agent-container {
    padding: 20px;
}


.close-button-invitation {
    position: absolute;
    right: 0;
    top: -32px;
    cursor: pointer;
}

.close-button-invitation img {
    transition: all .2s ease;
}

.close-button-invitation:hover > img {
    filter: brightness(10%);
}

/* Invitations End */


/*Animation on index page*/

/*show channels animation*/
@keyframes fadeUp {
    from {
        opacity: 0;
        transform: scale(1, 0.4);
    }

    to {
        opacity: 1;
        transform: scale(1, 1);
    }
}

/*hide channels animation*/
@keyframes fadeOut {
    from {
        opacity: 1;
        transform: scale(1, 1);
    }

    to {
        opacity: 0;
        transform: scale(1, 0);
    }
}

/*show widget animation*/
@keyframes showIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    30% {
        transform: scale(0.5);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* transform invitation animation on */
@keyframes transformIn {
    0% {
        opacity: 0;
        transform: scale(0, 0);
    }

    80% {
        transform: scale(1, 1);
    }

    100% {
        opacity: 1;
    }
}

/* transform invitation animation off */
@keyframes transformInOff {
    0% {
        opacity: 1;
        transform: scale(1, 1);
    }

    50% {
        opacity: 0;
    }

    100% {
        transform: scale(0, 0);
        /*opacity: 0;*/
    }
}

/* widget transform to invitation animation on */
@keyframes transformInWidget {
    0% {
        transform: scale(1, 1);
        opacity: 1;
    }

    100% {
        transform: scale(2, 2);
        opacity: 0;
    }
}

/* widget transform to invitation animation off */
@keyframes transformInWidgetOff {
    0% {
        transform: scale(2, 2);
        opacity: 0;
        border: 0;
    }

    100% {
        transform: scale(1, 1);
        opacity: 1;
        border: 4px solid #37409f;
    }
}

/* opacity invitation item animation */
@keyframes opacity {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/*gray icon settings*/
.Messenger .disconnection-icon {
    opacity: 0.28;
}

.Instagram .disconnection-icon {
    opacity: 0.23;
}

.Ok .disconnection-icon {
    opacity: 0.34;
}

.Skype .disconnection-icon {
    opacity: 0.39;
}

.Telegram .disconnection-icon {
    opacity: 0.34;
}

.Twitter .disconnection-icon {
    opacity: 0.39;
}

.Viber .disconnection-icon {
    opacity: 0.23;
}

.VK .disconnection-icon {
    opacity: 0.28;
}

.WhatsApp .disconnection-icon {
    opacity: 0.35;
}

.channels-wrapper-messenger img {
    filter: grayscale(100%);
    opacity: .4;
}

.channels-wrapper-messengers-block.custom-channels img {
    opacity: 1;
    filter: grayscale(0);
}

/*gray icon settings widget*/
.channels-wrapper-messenger.Messenger img {
    opacity: 0.28;
}

.channels-wrapper-messenger.Instagram img {
    opacity: 0.23;
}

.channels-wrapper-messenger.Ok img {
    opacity: 0.34;
}

.channels-wrapper-messenger.Skype img {
    opacity: 0.39;
}

.channels-wrapper-messenger.Telegram img {
    opacity: 0.34;
}

.channels-wrapper-messenger.Twitter img {
    opacity: 0.39;
}

.channels-wrapper-messenger.Viber img {
    opacity: 0.23;
}

.channels-wrapper-messenger.Vk img {
    opacity: 0.28;
}

.channels-wrapper-messenger.WhatsApp img {
    opacity: 0.35;
}
