.container {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    padding: 20px 40px 0 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.container .content {
    position: relative;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link {
    display: flex;
}

.link span {
    display: inline-block;
    margin-right: 10px;
    color: #646566;
}

.link a {
    position: relative;
    color: #000;
    font-weight: 500;
    text-decoration: none;
}

.link a::before {
    position: absolute;
    content: '';
    bottom: -2px;
    left: 0;
    width: 0;
    height: 1px;
    background: #000;
    transition: .3s ease;
}

a:hover::before {
    width: 100%;
}

.form {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 450px;
    background: #999;
}

.footer {
    position: relative;
    bottom: 0;
    left: 0;
    padding: 40px 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.copy {
    color: #7e7f80;
    font-size: 12px;
}

.footerLinks a {
    color: #7e7f80;
    font-size: 12px;
    padding: 0 10px;
    border-right: 1px solid #999;
    text-decoration: none;
}

.footerLinks a:hover {
    color: #1b1e26;
}

.footerLinks a:last-child {
    border-right: 0;
    padding-right: 0;
}

@media (max-width: 540px) {

    .copy {
        margin-bottom: 8px;
    }

    .footerLinks a {
        padding: 0 6px;
    }

    .footerLinks a:first-child {
        padding: 0 6px 0 0;
    }

    .footer {
        align-items: flex-start;
        flex-direction: column;
    }
}