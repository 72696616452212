@font-face {
  font-family: 'design-system';
  src: url('../font/design-system.eot?2986208');
  src: url('../font/design-system.eot?2986208#iefix') format('embedded-opentype'),
       url('../font/design-system.woff2?2986208') format('woff2'),
       url('../font/design-system.woff?2986208') format('woff'),
       url('../font/design-system.ttf?2986208') format('truetype'),
       url('../font/design-system.svg?2986208#design-system') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'design-system';
    src: url('../font/design-system.svg?2986208#design-system') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "design-system";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-menu:before { content: '\e804'; } /* '' */
.icon-arrow:before { content: '\e805'; } /* '' */
.icon-check:before { content: '\e806'; } /* '' */
.icon-close:before { content: '\e807'; } /* '' */
.icon-date-icon:before { content: '\e808'; } /* '' */
.icon-date-sm-icon:before { content: '\e809'; } /* '' */
.icon-error-icon:before { content: '\e80a'; } /* '' */
.icon-export-icon:before { content: '\e80b'; } /* '' */
.icon-flag:before { content: '\e80c'; } /* '' */
.icon-menu-1:before { content: '\e80d'; } /* '' */
.icon-round-error:before { content: '\e80e'; } /* '' */
.icon-round:before { content: '\e810'; } /* '' */
.icon-success-icon:before { content: '\e811'; } /* '' */
.icon-warning-icon:before { content: '\e812'; } /* '' */
.icon-close-panel:before { content: '\e813'; } /* '' */
.icon-note:before { content: '\e814'; } /* '' */
.icon-resend:before { content: '\e815'; } /* '' */
.icon-option:before { content: '\e816'; } /* '' */
.icon-dots:before { content: '\e817'; } /* '' */