.container {
    position: relative;
    width: 376px;
    min-width: 376px;
    height: 692px;
}

@media(min-width: 1850px) {
    .container {
        position: sticky;
        top: 50px;
    }
}
