@import "../../variables";

// analytics chart
.chart {
  width: 100%;
  height: 240px;
  font-size: 10px;
  box-sizing: border-box;
  font-family: $main-fonts;
}

