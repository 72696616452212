@import "../../variables";

// popup body
.popupBody {
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}
