@import "../../variables";

.subMenu {
  width: 240px;
  height: 100%;
  //min-height: 540px;
  background-color: #1b1e26;
  position: absolute;
  left: 60px;
  top: 0;
  bottom: 0;
  padding: 0 24px;
  min-height: 500px;
  font-family: $main-fonts;
  box-sizing: border-box;
}

.submenuHeader {
  display: flex;
  height: 80px;
  align-items: center;
}

.submenuHeaderTitle {
  font-size: 20px;
  font-weight: 500;
  cursor: default;
  padding-bottom: 5px;
  color: #fff;
}

.submenuContent {
  margin-top: 14px;
}

.listCategory {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    margin: 16px 0 10px 0;
    cursor: default;
    font-size: 12px;
    font-weight: 500;
    color: #45474c;
 }

.submenuContentList {
  margin-bottom: 35px;
}

  .listItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    color: #5c5e66;
    font-size: 14px;
    margin-bottom: 12px;
    text-decoration: none;
    transition: all .2s ease;
}

.listItemCol {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.listItemText {
  position: relative;
  margin-right: 10px;
}

.counterWrapper {
  position: relative;
}

.tooltip {
  position: absolute;
  top: -10px;
  color: #000000;
  display: flex;
  height: 20px;
  min-width: 10px;
  padding: 0 5px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.tooltipText {
  font-size: 10px;
}

.counterAll {
  position: relative;
  margin-left: 10px;
}

.listItem:hover,
.listItem.active {
  color: #ffffff;
  .tooltip {
    color: #ffffff
  }
}