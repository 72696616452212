.content {
    position: sticky;
    z-index: 1000;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    box-sizing: border-box;
}
