.container {
    position: relative;
    width: 100%;
    max-width: 460px;
    min-width: 360px;
    margin-right: 40px;
    opacity: 1;
    transition: .15s ease;
}

.animate {
    opacity: 0;
}
