@import "../../variables";

// emoji
.emojiContainer {
  position: absolute;
  right: 0;
  bottom: 32px;
  height: 196px;
  width: 280px;
  background-color: $white;
  border-radius: 10px;
  padding: 12px;
  z-index: 9998;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  cursor: default;
  box-shadow: 0 3px 8px 0 rgba(32, 35, 38, 0.05),
  0 10px 20px 0 rgba(32, 35, 38, 0.1);
}

.emoji {
  padding: 6px;
  display: inline-block;
  box-sizing: border-box;
  line-height: .15;
}

.emojiIcon {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  word-break: keep-all;
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
  box-sizing: border-box;
  line-height: 1.15;
  user-select: none;
  transition: transform .1s ease;
}

.emojiIcon:hover {
  transform: scale(1.2);
}

.emojiContainer::-webkit-scrollbar {
  width: 0 !important;
}

.emojiContainer {
  overflow: -moz-scrollbars-none;
}

.emojiContainer {
  -ms-overflow-style: none;
}

.emojiContainer {
  scrollbar-width: none;
}

// open animation
.emojiContainer {
  transition: all 0.2s ease;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transform: scale(1, 0);
  transform-origin: bottom right;
}

.emojiContainer.open {
  transition: all 0.2s ease;
  z-index: 9999;
  opacity: 1;
  visibility: visible;
  transform: scale(1, 1);
}