.footer {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    bottom: 0;
    right: 0;
    padding: 0 24px;
    height: 80px;
    width: auto;
    z-index: 50;
}

.footer button:not(:first-child) {
    margin-left: 10px;
}

.footer:after {
    content: '';
    position: absolute;
    right: 3px;
    bottom: 0;
    width: 10px;
    height: 10px;
    border-radius: 100% 0 0 0;
    z-index: -1;
    /*box-shadow: 0 0 86px 100px rgba(255,255,255,1);*/
}
