.container {
    position: relative;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 80px !important;
    height: 80px;
    border: 1px solid #e8e9ec;
    border-radius: 45%
}

.icon {
    width: 64px;
    height: 64px;
}

