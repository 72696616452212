.form {
    position: relative;
    width: 100%;
    border-radius: 5px;
}

.form::before {
    position: absolute;
    content: '';
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    opacity: 0;
    visibility: hidden;
    border-radius: 5px;
    transition: .3s ease;
    background: rgba(237,239,242,.1);
    border: 1px dashed #9d9ea0;
}

.form.isDrag::before {
    opacity: 1;
    visibility: visible;
}

