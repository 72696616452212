@import "../../variables";

.inputBlock {
  position: relative;
  width: 100%;
  line-height: 14px !important;
  font-family: $main-fonts;
}

.label {
  position: relative;
  display: inline-block;
  margin-bottom: 8px;
  color: $black;
  transition: all .2s ease;
  cursor: text;
  font-size: 14px;
  pointer-events: none;
  user-select: none;
}

.textareaContainer  {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 80px;
}

.textarea {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  width: 100%;
  outline: 0;
  border: 1px solid $input-border;
  border-radius: 5px;
  font-size: 14px;
  padding: 12px 24px 12px 12px;
  resize: none;
  box-sizing: border-box !important;
  transition: all .2s ease;
  font-family: $main-fonts;
}

// input hover
.inputBlock:hover .textarea  {
  border: 1px solid $input-hover-border;
}

// input focused
.focused .textarea  {
  border: 1px solid $input-hover-border;
  box-shadow: $input-focus-shadow;
}

.focused .label {
  color: $black !important;
  border: none;
}

// non empty
.nonEmpty > .label {
  color: $label-color;
}

// input disabled
.disabled .textarea  {
  background-color: $disabled-input;
  border: 1px solid $disabled-input !important;
  pointer-events: none;
  transition: none;
}

.disabled .textarea  .textarea {
  background-color: $disabled-input;
}

.disabled:hover .textarea  {
  border: none;
  transition: none;
}

.disabled .validationMessage {
  visibility: hidden !important;
}

.disabled .clearButton {
  visibility: hidden !important;
}

.disabled .label {
  color: $label-color !important;
}

// clear button
.clearButton {
  width: 8px;
  height: 100%;
  position: absolute;
  right: 12px;
  bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease;
}

.clearButton i {
  font-size: 8px !important;
  color: $clear-button-color;
  padding: 11px;
  position: relative;
}

.inputBlock.focused.nonEmpty .clearButton {
  opacity: 1;
  visibility: visible;
  transition: opacity .2s ease;
}

.inputBlock.nonEmpty:hover .clearButton {
  opacity: 1;
  visibility: visible;
  transition: opacity .2s ease;
}

.clearButton i:hover {
  color: $black;
}

// validation
.success .textarea  {
  border: 1px solid $input-success;
}

.focused.success .textarea  {
  border: 1px solid $input-success;
}

.success .textarea :hover {
  border: 1px solid $input-success;
}

.focused.success .textarea  {
  box-shadow: $input-success-shadow;
}

.warning .textarea  {
  border: 1px solid $input-warning;
}

.focused.warning .textarea  {
  border: 1px solid $input-warning;
}

.warning .textarea :hover {
  border: 1px solid $input-warning;
}

.focused.warning .textarea  {
  box-shadow: $input-warning-shadow;
}

.error .textarea  {
  border: 1px solid $input-error;
}

.focused.error .textarea  {
  border: 1px solid $input-error;
}

.error .textarea :hover {
  border: 1px solid $input-error;
}

.focused.error .textarea  {
  box-shadow: $input-error-shadow;
}

// validation message
.validationMessage {
  color: $input-default;
  position: relative;
  padding: 4px 0;
  left: 0;
  font-size: 12px;
  visibility: visible;
}

.success .validationMessage {
  color: $input-success;
  visibility: visible;
}

.warning .validationMessage {
  color: $input-warning;
  visibility: visible;
}

.error .validationMessage {
  color: $input-error;
  visibility: visible;
}

//remove placeholder when input focus
.textarea::-webkit-input-placeholder {
  color: $placeholder-color !important;
  font-family: $main-fonts;
}

.textarea:focus::-webkit-input-placeholder {
  text-indent: -999px
}

.textarea::-moz-placeholder {
  color: $placeholder-color !important;
  font-family: $main-fonts;
}

.textarea:focus::-moz-placeholder {
  text-indent: -999px
}

.textarea:-moz-placeholder {
  color: $placeholder-color !important;
  font-family: $main-fonts;
}

.textarea:focus:-moz-placeholder {
  text-indent: -999px
}

//resize textarea
.resizeTextarea .textareaContainer {
  height: auto !important;
  width: auto !important;
}

.resizeTextarea .textarea{
  resize: vertical !important;
}