.container {
    position: relative;
    cursor: pointer;
    user-select: none !important;
    width: 100%;
    font-size: 14px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background: #fff;
    outline: none;
    border: 1px solid #d9dbde;
    transition: .3s ease;
}

.container:hover {
    border: 1px solid #bbbcbf;
}

.messenger {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    padding-left: 20px;
    align-items: center;
}

.icon {
    position: relative;
    width: 36px;
    min-width: 36px;
    max-height: 36px;
    height: auto;
    transition: .3s ease;
}

.disconnection {
    opacity: .4;
    filter: grayscale(100%);
}

.name {
    font-weight: 500;
}

.desc {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 280px;
    min-width: 100px;
}

.link {
    padding-top: 5px;
    opacity: .5;
    width: 100%;
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    text-align: left;
}

.status {
    position: absolute;
    overflow: hidden;
    border-radius: 0 5px 5px 0;
    right: 0;
    height: 100%;
    font-family: -apple-system,BlinkMacSystemFont,"Source Sans Regular","Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    padding: 0 20px;
    display: flex;
    background: #fff;
    outline: none;
    border: none;
    cursor: pointer;
    align-items: center;
    font-weight: 500;
}

/*.status::before {*/
/*    position: absolute;*/
/*    content: '';*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    transition: .2s ease;*/
/*    opacity: 0;*/
/*    transform: translateX(10px);*/
/*    background: linear-gradient(110deg, #fff 50%, rgba(55,55,55,.05) 100%);*/
/*}*/

.status span {
    position: relative;
}

.status span::before {
    position: absolute;
    content: '';
    bottom: -1px;
    left: 0;
    width: 0;
    height: 1px;
    background: #333;
    opacity: 0;
    transition: .3s ease;
}

/*.status:hover::before {*/
/*    opacity: 1;*/
/*    transform: translateX(0);*/
/*}*/

.status:hover span::before {
    opacity: 1;
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .desc {
        max-width: 180px!important;
    }
}
