@import "../../variables";

.select {
  font-family: $main-fonts;
  line-height: 14px;
  position: relative;
  width: 100%;
  height: 100%;
}

.label {
  position: relative;
  display: inline-block;
  margin-bottom: 8px;
  color: $black;
  transition: all .2s ease;
  cursor: text;
  font-size: 14px;
  pointer-events: none;
  user-select: none;
}

.nonEmpty .label {
  color: $label-color;
}

.open {
  .arrowButton {
    transform: rotate(180deg);
  }

  .label {
    color: #000;
  }

  .formControl {
    border: 1px solid $light-200;
    box-shadow: 0 0 0 2px rgba(225, 227, 230, 1);
  }
}

.focus {
  border: 1px solid $light-200;
  box-shadow: 0 0 0 2px rgba(225, 227, 230, 1);

  .arrowButton {
    transform: rotate(180deg);
  }
}

.formControl {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  outline: 0;
  border: 1px solid $input-border;
  border-radius: 5px;
  font-size: 14px;
  box-sizing: border-box;
  transition: all .2s ease;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none !important;
  -khtml-user-drag: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -moz-user-select: -moz-none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  background: #fff;
}

.select:hover .formControl {
  border: 1px solid $input-hover-border;
}

.input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 38px;
  padding: 0 12px;
  box-sizing: border-box;
  position: relative;
}

.filterInput {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  outline: 0;
  border: none;
  border-radius: 5px;
  padding: 0 28px 0 12px;
  margin-left: -12px;
  font-size: 14px;
  box-sizing: border-box;
  //transition: all .2s ease;
  background: transparent;
  color: transparent;
  z-index: 2;
}

.placeholder {
  color: $placeholder-color !important;
}

.open .filterInput {
  background: #fff;
  color: #000;
}

.sm .input {
  min-height: 34px !important;
}

.lg .input {
  min-height: 46px !important;
}

.arrowButton {
  height: 100%;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 12px;
  bottom: 0;
  cursor: pointer;
  opacity: 1;
  pointer-events: none;
  font-size: 6px !important;
  color: #7d7e80;
  transition: transform .2s ease;
  z-index: 2;

  i::before {
    margin: 0;
    width: 12px;
  }
}

.selectDropdown {
  transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scaleY(0.8);
  transform: scaleY(0.8);
  visibility: hidden;
  will-change: transform;
  position: absolute;
  width: 100%;
  margin-top: 2px;
  max-height: 320px;
  overflow: auto;
  left: 0;
  right: 0;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(27, 30, 38, .03) 0 0 0 1px, rgba(27, 30, 38, .1) 0 8px 24px;
  z-index: 40;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.selectDropdown.fullHeight {
  max-height: none;
}

.selectDropdown.right {
  position: absolute;
  width: 180px;
  left: 20px;
  top: 0;
}

.selectDropdown.left {
  position: absolute;
  width: 180px;
  left: -185px;
  top: 0;
}

.selectDropdown.top {
  width: 220px;
  bottom: 67px;
  transform-origin: bottom center;
}

.selectDropdown.leftTop {
  position: absolute;
  transform-origin: bottom center;
  width: 180px;
  left: -185px;
  bottom: 20px;
}

.dropdownItem {
  position: relative;
  height: 40px;
  overflow: hidden;

  a {
    color: #000000;
    width: 100%;
    height: 100%;
    padding: 12px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background-color: $drp-li-hover;
    }
  }

  &:hover .arrow {
    display: block !important;
  }
}

.arrow {
  display: none;
  position: absolute;
  width: 10px;
  right: 12px;
  font-size: 12px;
  color: #7d7e80;
}

.isLogout .dropdownItem {
  border-top: 1px solid #f0f0f0;
  a {
    transition: .2s ease;
    color: #a0a2a3;
    &:hover {
      background: none;
      color: #000;
    }
  }
}

.itemCheck {
  position: absolute;
  right: 12px;
  top: 0;
  pointer-events: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  i {
    margin: 0 !important;
    font-size: 12px !important;
    color: #000 !important;

    &::before {
      margin: 0;
    }
  }
}

.open .selectDropdown {
  transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  visibility: visible;
  will-change: transform;
}

.empty {
  user-select: none;
  width: 100%;
  padding: 13px 12px;
  font-size: 14px;
}

// multi
.multiSelect {
  .formControl {
    padding: 0;
  }
}

.multiSelectContainer {
  position: relative;
}

.multiSelectItems {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  max-height: 200px;
}

.multiSelectItem {
  display: flex;
  position: relative;
  height: 36px;
  flex: 0 0 36px;
  outline: 0;
  box-sizing: border-box !important;
  margin: 2px;
  border-radius: 4px;
  box-shadow: 0 1px 1.98px 0.02px rgba(217, 219, 222, 1);
  align-items: center;
  padding: 0 12px;
  font-size: 14px;
  transition: all .2s ease;

  &:hover {
    box-shadow: 0 1px 1.98px 0.02px rgba(175, 176, 178, 1);
  }
}

.sm {
  .multiSelectItems {
    max-height: 200px;
  }

  .multiSelectItem {
    height: 36px !important;
    flex: 0 0 36px !important;
  }

  .dropdownItem {
    height: 36px !important;
  }
}

.lg {
  .multiSelectItems {
    max-height: 208px;
  }

  .multiSelectItem {
    height: 48px !important;
    flex: 0 0 48px !important;
  }

  .dropdownItem {
    height: 48px !important;
  }
}

.hideInput .input {
  display: none;
}

//.hideInput .multiSelectItem:last-child {
//  margin-bottom: 0;
//}

.clearButton {
  visibility: visible;
  opacity: 1;
  width: 16px;
  position: absolute;
  right: 8px;
  bottom: 0;
  cursor: pointer;
  transition: opacity .2s ease;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 8px !important;
    color: #7d7e80;

    &::before {
      margin: 0 !important;
    }
  }

  &:hover i {
    color: #000;
  }
}

// validation
.success .formControl {
  border: 1px solid $input-success;
}

.open.success .formControl {
  border: 1px solid $input-success;
}

.success .formControl:hover {
  border: 1px solid $input-success;
}

.open.success .formControl {
  box-shadow: $input-success-shadow;
}

.warning .formControl {
  border: 1px solid $input-warning;
}

.open.warning .formControl {
  border: 1px solid $input-warning;
}

.warning .formControl:hover {
  border: 1px solid $input-warning;
}

.open.warning .formControl {
  box-shadow: $input-warning-shadow;
}

.error .formControl {
  border: 1px solid $input-error;
}

.open.error .formControl {
  border: 1px solid $input-error;
}

.error .formControl:hover {
  border: 1px solid $input-error;
}

.open.error .formControl {
  box-shadow: $input-error-shadow;
}

.multiSelectItem.error {
  background-color: #f7d9df;
  box-shadow: 0 1px 1.98px 0.02px rgba(211, 0, 67, .3);

  &:hover {
    box-shadow: 0 1px 1.98px 0.02px rgba(192, 41, 70, 1);
  }
}

.multiSelectItem.warning {
  background-color: #f4efd4;
  box-shadow: 0 1px 1.98px 0.02px rgba(202, 173, 39, .5);

  &:hover {
    box-shadow: 0 1px 1.98px 0.02px rgba(197, 173, 69, 1);
  }
}

// validation message
.validationMessage {
  position: relative;
  padding: 4px 0;
  left: 0;
  font-size: 12px;
  visibility: hidden;
}

.success .validationMessage {
  color: $input-success;
  visibility: visible;
}

.warning .validationMessage {
  color: $input-warning;
  visibility: visible;
}

.error .validationMessage {
  color: $input-error;
  visibility: visible;
}

// input disabled

.disabled .formControl {
  cursor: default;
  background-color: $disabled-input;
  border: 1px solid $disabled-input !important;
  transition: none;
}

.disabled:hover .formControl {
  border: none;
  transition: none;
}

.disabled .validationMessage {
  visibility: hidden !important;
}

.disabled .label {
  color: $label-color !important;
}

.disabled {
  &.multiSelect.nonEmpty .input {
    display: none;
  }

  .clearButton {
    display: none;
  }
}

.disabled .multiSelectItem {
  pointer-events: none;
}

// types
.withAvatar {
  display: flex;
  width: 100%;
  align-items: center;

  .avatar {
    margin-right: 8px;
  }

  .content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
    line-height: 20px;
  }
}

.color {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  font-size: 10px;
  color: rgb(255, 255, 255);
  position: relative;
  border-radius: 5px;
  margin: 3px 0;
}

.selectDropdownColor {
  display: flex;
  flex-direction: column;
  max-width: 240px;
  left: auto;
  padding: 17px 8px;
}

.colorRow {
  display: flex;
  justify-content: space-between;
}

.toggleColor {
  display: flex;
  width: 100%;
  align-items: center;

  .color {
    margin-right: 6px;
  }

  .selectedColor:after {
    color: rgb(255, 255, 255);
  }
}

.iconContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 10px;
  min-height: 20px;
  width: 100%;
  height: 100%;
}

.iconContainer:hover .icon svg path {
  fill: #000
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuDivider {
  border-top: 1px solid $light-600;
}

.menuLabel {
  color: $light-100;
  padding: 15px 12px 7px 12px;
  font-size: 11px;
}

.toggleContentContainer {
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.toggleContent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.contentIcon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 6px;
  width: 12px;
  margin-left: 8px;
  padding-top: 4px;
  color: #7d7e80;
}

.contentIcon svg {
  fill: #7d7e80;
  transition: all .2s ease;
}

.isOpenContent svg{
  fill: #000;
  transform: rotate(180deg);
  transition: all .2s ease;
}

.toggleContent:hover .contentIcon svg{
  fill: #000;
}





.listItem {
  position: relative;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 0 12px;
  text-decoration: none;
  color: #000;
}

.listItemText {
  position: relative;
  margin-right: 10px;
}

.counterWrapper {
  position: relative;
}

.tooltip {
  position: absolute;
  top: -10px;
  color: #fff;
  display: flex;
  height: 20px;
  min-width: 10px;
  padding: 0 5px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background: #d9dbde;
}

.tooltipText {
  font-size: 10px;
}

.counterAll {
  position: relative;
  margin-left: 10px;
}

.listItem:hover,
.listItem.active {
  color: #000;
  background-color: $drp-li-hover;
  .tooltip {
    color: #fff
  }
}

.listItem.active {
  font-weight: 500;
}

.listItemCol {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.iconContainer.positionRelative {
  position: relative;
}
