@import "../../variables";

// password change button
.inputTextButton {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  color: $light-100;
  font-size: 14px;
  user-select: none;
  padding-right: 12px;
  padding-left: 0;
  border-radius: 0 5px 5px 0;
  z-index: 2;
  transition: all .2s ease;
}

.inputTextButton:hover {
  color: $black;
}
