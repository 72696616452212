@import "../../variables";

//modal overlay
.modalOverlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(27, 30, 38, .5);
  transition: all .2s ease;
  opacity: 0;
  visibility: hidden;
  z-index: 1060;
}

// modal
.modal {
  position: relative;
  width: 620px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  box-shadow: 0 20px 32px 8px rgba(37, 40, 50, 0.2);
  border-radius: 5px;
  padding: 40px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: $main-fonts;
  -webkit-transform: scale(.7);
  transform: scale(.7);
  opacity: 0;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

//show overlay and modal
.open {
  opacity: 1;
  transition: all .2s ease;
  visibility: visible;
}

.open .modal {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

// close button
.closeButton {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 11px;
  top: 11px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButton:hover i {
  color: $black;
}

.closeButton i {
  font-size: 10px;
  color: $clear-button-color;
  margin: 0 !important;
  padding: 0;
  transition: color .2s ease;
}

.closeButton i:before {
  margin: 0 !important;
  padding: 0;
}

// modalBody
.modalBody {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.modalBodyIcon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  animation: opacity .6s alternate;
  animation-fill-mode: forwards;
}

.modalBodyIcon i {
  font-size: 32px;
  animation: opacity .6s alternate;
  animation-fill-mode: forwards;
}

.modalBodyIcon i:before {
  margin: 0;
  padding: 0;
}

.successIcon {
  color: $green !important;
}

.errorIcon {
  color: $red !important;
}

//modal text block
.modalBodyText {
  animation: opacity .6s alternate;
  animation-fill-mode: forwards;
}

.modalBodyText p {
  padding: 0;
  margin: 0;
  min-height: 32px;
  display: flex;
  align-items: center;
  line-height: 24px;
  animation: opacity .6s alternate;
  animation-fill-mode: forwards;
}

// modal footer
.modalFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
}

.modalFooter button {
  animation: opacity .4s alternate;
  animation-fill-mode: forwards;
}

.modalFooter button:last-child {
  margin-left: 10px;
}

//modal blocks animation
@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}