@import "../../variables";

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  font-family: $main-fonts;
  background: $white;
}

.tableHeader {
  display: flex;
  z-index: 5;

  &.fixed {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
  }
}

.tableHeaderColumn {
  display: flex;
  width: 160px;
  min-width: 160px;
  height: 40px;
  align-items: center;
  padding: 0 12px;
  color: $light-100;
  flex-shrink: 0;
  background: $white;
  box-sizing: border-box;

  &:first-of-type {
    padding-left: 24px;
  }

  &:last-of-type {
    padding-right: 24px;
  }

  &.fixed {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 30;
  }

  &.tableRowMenu {
    width: 50px;
    min-width: 50px;
  }
}

.tableBody {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.tableRow {
  display: flex;
  position: relative;
  text-decoration: none;

  &:hover,
  &.active {
    z-index: 4;

    .tableColumn {
      background-color: $table-row-border-color;

      &:first-of-type::before {
        top: -1px;
        left: 0;
        right: 0;
        bottom: auto;
        width: auto;
      }

      &::before {
        content: ' ';
        position: absolute;
        top: -1px;
        left: -12px;
        right: -12px;
        height: 1px;
        background: $white;
        z-index: 2;
      }

      span[class^="Avatar_channel"] {
        border-color: $table-row-border-color;;
      }
    }

    .tableRowMenu > .tableRowMenuIcon {
      opacity: 1;
    }

    &.status > .tableColumn:first-of-type::after {
      display: block;
    }
  }

  &.status > .tableColumn:first-of-type::after {
    display: none;
    content: ' ';
    position: absolute;
    top: 0;
    bottom: -1px;
    left: 0;
    width: 3px;
    z-index: 2;
  }

  &.statusGray > .tableColumn:first-of-type::after {
    background: #bbbcbf;
  }

  &.statusRed > .tableColumn:first-of-type::after {
    background: #c02546;
  }

  &.statusYellow > .tableColumn:first-of-type::after {
    background: #caad28;
  }
}

.tableColumn {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  width: 160px;
  min-width: 160px;
  text-decoration: none !important;
  color: initial;
  height: 80px;
  padding: 0 12px;
  border-bottom: 1px solid $table-row-border-color;
  background: $white;
  box-sizing: border-box;
  position: relative;
  transition: height .2s ease;

  &:first-of-type {
    padding-left: 24px;

    &::before {
      content: ' ';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 24px;
      height: 1px;
      background: $white;
      z-index: 2;
    }
  }

  &:last-of-type {
    padding-right: 24px;
  }

  &.fixed {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 3;
  }

  &.isSwitcher {
    display: flex;
    padding: 0;
    justify-content: flex-end;
  }

  &.tableRowMenu {
    position: -webkit-sticky;
    position: sticky;
    user-select: none !important;
    right: 0;
    width: 50px;
    min-width: 50px;

    .tableRowMenuIcon {
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .tableRowMenuContent {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      right: 0;
      cursor: pointer;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;

    }

    &:hover .tableRowMenuIcon svg path {
      fill: #000;
    }
  }

  &.active {
    z-index: 35;

    .tableRowMenuIcon {
      opacity: 1;
    }
  }
}

.tableColumn.isAvatar {
  &::before {
    width: $table-row-border-padding;
  }
}

.isAvatar > span:first-of-type {
  margin-right: 10px;
}

.justified {
  flex: 1;
}

// no hover
.noHover {
  .tableRow {
    &:hover,
    &.active {
      z-index: 1 !important;

      .tableColumn {
        background: #fff !important;
      }

      .tableRowMenu > .tableRowMenuIcon {
        opacity: 1;
      }

      &.status > .tableColumn:first-of-type::after {
        display: block;
      }
    }
  }

  .tableColumn {
    background: #fff !important;

    &:first-of-type::before {
      display: none !important;
    }

    &::before {
      display: none !important;
    }
  }
}

// header border
.headerBorder {
  .tableHeader {
    border-bottom: 1px solid #d9dbde;
  }

  .tableColumn {
    &::before {
      display: none !important;
    }
  }
}

// without padding
.withoutPadding {
  .tableHeaderColumn {
    &:first-of-type {
      padding-left: 0 !important;
    }
  }

  .tableRow {
    border-bottom: 1px solid #f2f4f7;
  }

  .tableColumn {
    border: none !important;

    &:first-of-type {
      padding-left: 0 !important;
    }

    &:first-of-type::before {
      display: none !important;
    }

    &::before {
      display: none !important;
    }

  }
}

.disabled .tableColumn:not(.tableRowMenu) {
  color: #afb0b3 !important;
}

.disabled .tableColumn span[class^="Avatar"] {
  opacity: .65;
}

// height
.height40 .tableColumn {
  height: 40px !important;
}

.height48 .tableColumn {
  height: 48px !important;
}

.height64 .tableColumn {
  height: 64px !important;
}

.height40 .tableColumn.isAvatar {
  &::before {
    width: 58px !important;
  }
}

.height40:hover > .tableColumn:before {
  width: auto !important;
}

.height48 .tableColumn.isAvatar {
  &::before {
    width: 66px !important;
  }
}

.height48:hover > .tableColumn:before {
  width: auto !important;
}

.height64 .tableColumn.isAvatar {
  &::before {
    width: 74px !important;
  }
}

.height64:hover > .tableColumn:before {
  width: auto !important;
}


.p0 {
  padding: 0 !important;
}