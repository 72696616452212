.track {
  z-index: 11;

  &:hover {
    opacity: 1 !important;
  }

  :global(.dragging) {
    opacity: 1 !important;
  }
}
