input {
  display: flex;
  height: 100%;
  width: 100%;
  outline: 0;
  border: none;
  border-radius: 5px;
  padding: 0 28px 0 12px;
  font-size: 14px;
  box-sizing: border-box !important;
  transition: all .2s ease;
}

.datePickerSingle.isFocus div[class*="inputContainer"] {
  box-shadow: 0 0 0 2px #e1e3e6;
  border-color: #afb0b3;
}

.datePickerSingle.isFocus label {
  color: #000
}

.datePickerSingle div[class*="react-datepicker__input-container"] {
  width: 280px;
}


.iconDate {
  position: absolute;
  right: 10px;
  bottom: 13px;
  color: #7d7e80;
  pointer-events: none;
  transition: .15s ease;
}

.iconSm {
  bottom: 11px;
  right: 10px;
}

.iconLg {
  bottom: 16px;
  right: 10px;
}

.isFocus .iconDate {
  color: #000000;
}

.years {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
}

.years button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 30px;
  height: 50px;
  background: none !important;
  outline: none !important;
  border: none !important;
}

.years button:hover svg path {
  transition: .2s ease;
  fill: #000 !important;
}

.prevYear {
  left: 10px !important;
}
.nextYear {
  right: 10px !important;
}

.months {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.months button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 30px;
  height: 50px;
  background: none !important;
  outline: none !important;
  border: none !important;
}

.months button:hover svg path {
  transition: .2s ease;
  fill: #000 !important;
}

.prevMonth {
  left: 33px !important;
}
.nextMonth {
  right: 33px !important;
}

.dateRange {
  display: flex;
  max-width: 280px;
}

.dateRange .pickerTo {
  right: 0 !important;
}

.dateRange div[class*="react-datepicker-popper"] {
  transform: translate3d(0px, 36px, 0px)!important;
}

.dateRange {
  border-radius: 0 !important;
}

.dateRange div[class*="inputContainer"] {
  position: relative;
  box-shadow: none !important;
  border-radius: 0 5px 5px 0;
}

.dateRange div[class*="react-datepicker-wrapper"]:first-of-type div[class*="inputContainer"] {
  border-radius: 5px 0 0 5px;
  border-right: 0;
}

.dateRange div[class*="react-datepicker-wrapper"]:last-of-type div[class*="inputContainer"] {
  border-radius: 0 5px 5px 0 !important;
}

.dateRange:hover div[class*="inputContainer"] {
  border-color: #afb0b3;
}

.dateRange div[class*="react-datepicker-wrapper"]:first-of-type div[class*="inputContainer"]::after {
  position: absolute;
  content: '';
  top: 0;
  right: -3px;
  width: 6px;
  height: 100%;
  background: #fff;
  z-index: 100;
}

.dateRange div[class*="react-datepicker-wrapper"]:first-of-type div[class*="inputContainer"]::before {
  position: absolute;
  content: '';
  top: -1px;
  right: 0;
  width: 1px;
  height: calc(100% + 2px);
  background: #d9dbde;
  z-index: 101;
}

.dateRange:hover div[class*="react-datepicker-wrapper"]:first-of-type div[class*="inputContainer"]::before {
  background: #afb0b3;
}

.isFocus.dateRange div[class*="inputContainer"] {
  box-shadow: 0 0 0 2px #d9dbde !important;
  border-color: #afb0b3;
}

.isFocus.dateRange div[class*="react-datepicker-wrapper"]:first-of-type div[class*="inputContainer"]::before {
  background: #afb0b3;
}

.dateRange:active div[class*="inputContainer"] {
  box-shadow: 0 0 0 2px #d9dbde !important;
}

.noLabel label{
  display: none!important;
}

