@import "../../variables";

// tooltip wrapper
.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: inline-block;
  z-index: 10;

  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
    z-index: 1060;
  }
}

// tooltip
.tooltip {
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: calc(100% + 4px);
  transform: translateY(-50%);
  height: 24px;
  width: auto;
  max-width: 280px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  background-color: rgba(239, 240, 243, 0.902);
  border: 1px solid #d9dade;
  padding: 0 12px;
  font-size: 10px;
  color: $black;
  box-shadow: 0 5px 5px 0 rgba(37, 40, 50, 0.05);
  transition: opacity 0.2s ease;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  min-width: 40px;
  white-space: nowrap;
  box-sizing: border-box;
  font-family: $main-fonts;
}

// size
.lg {
  height: 36px;
  padding: 0 16px;
  font-size: 12px;
}

// dark style
.dark {
  background-color: $black;
  border: 1px solid $black;
  color: $white;
}

// position
.tooltip.right {
  top: 50%;
  right: auto;
  bottom: auto;
  left: calc(100% + 8px);
  transform: translateY(-50%);
}

.tooltip.left {
  top: 50%;
  right: calc(100% + 8px);
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}

.tooltip.top {
  top: auto;
  right: auto;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
}

.tooltip.bottom {
  top: calc(100% + 8px);
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

// withWrap
.withWrap {
  white-space: pre-wrap !important;
  padding: 12px;
  min-width: 240px;
  height: auto !important;
  line-height: 14px;
  width: 100%;
  min-width: 240px;
  max-width: 280px!important;
}

// rightBottom
.tooltip.rightBottom {
  top: auto;
  right: 0;
  bottom: -20px;
  left: auto;
  transform: translateY(0);
}

// withDelay
.wrapper {

  &:hover .tooltip.withDelay {
    transition: all .2s ease;
  }

  &:hover .tooltip.withDelay {
    transition: all .2s ease .6s;
  }

}