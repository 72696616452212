@import "../../variables";

// custom tooltip
.customTooltip {
  background: #000;
  color: #fff;
  padding: 12px;
  border-radius: 5px;
  line-height: 16px;
  font-family: $main-fonts;
}

