.container {
    width: 100%;
    max-width: 968px;
    padding: 80px 24px;
    margin: 0 auto;
}

@media(max-height: 920px) {
    .container {
        padding: 40px 24px 100px 24px;
    }
}