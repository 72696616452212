@import "../../variables";

// card
.card {
  width: 100%;
  padding: 36px 40px;
  border: 1px solid $light-700;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  overflow: hidden;
  font-family: $main-fonts;
}

// card header
.cardHeader {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// card body
.cardBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 36px;
  overflow: hidden;
}

.card p {
  padding: 0;
  margin: 0;
}

// title
.cardHeaderTitle {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.title p {
  font-size: 20px;
  font-weight: 600;
  max-width: 360px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

// info icon
.info {
  margin: 0 10px;
  display: flex;
  position: relative;
}

.info svg {
  fill: #7a7c80;
}

// desc text
.descriptionText p {
  font-size: 11px;
  color: $light-100;
  text-transform: uppercase;
  font-weight: 400;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

// noInfoLabel
.noInfoLabel .info{
  display: none!important;
}

// infoLabel
.infoLabel {
  height: 24px;
  min-height: 24px;
  width: auto;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid red;
  margin-left: 10px;
  box-sizing: border-box;
}

// with hover
.hover {
  transition: all .2s ease;
}

.hover:hover {
  border-color: $light-200;
}