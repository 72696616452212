@import "../../variables";

// switcher container
.RadioButtonContainer{
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-family: $main-fonts;
}

.RadioButtonContainer:hover > .inputRadioButton {
  border-color: $light-200;
}

.inputRadioButton {
  position: relative;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-transition: .2s ease;
  transition: .2s ease;
  border: 1px solid #dbdde0;
  box-sizing: border-box;
}

.RadioButtonInput {
  cursor: pointer;
  opacity: 0;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
}

.RadioButtonInner {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: .15s ease;
  transition: .15s ease;
  opacity: 0;
}

.RadioPoint {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #000;
  pointer-events: none;
  user-select: none;
}

.RadioButtonInput:checked ~ .RadioButtonInner {
  opacity: 1;
}

.RadioButtonLabel {
  color: $black;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 10px;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
}

// disabled
.disabled{
  pointer-events: none !important;
}

.disabled .inputRadioButton{
  pointer-events: none !important;
  background-color: #d4d5d9;
}

//small size
.RadioButtonContainer.sm .RadioButtonLabel {
  font-size: 12px;
}
