@import "../../variables";

.navMenu {
  display: flex;
  flex-direction: column;
  width: 60px;
  height: 100%;
  min-height: 500px;
  justify-content: space-between;
  background: #1b1e26;
  font-family: $main-fonts;
  border-right: 1px solid #2e2f33;
  color: #5c5e66;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 998;

  .menuContent {
    width: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    .menuList {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      .menuListItem {
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        color: #5c5e66;
        transition: all .2s ease;
        text-decoration: none;

        &:hover {
          color: #fff;

          .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6 {
            color: #fff;
          }

          svg g {
            fill: #fff;
            transition: fill .2s ease;
          }
        }

        svg g {
          fill: #5c5e66;
          transition: fill .2s ease;
        }

        .counter {
          position: absolute;
          pointer-events: none;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          width: 24px;
          height: 24px;
          background: #c02546;
          line-height: 1.3;
          border-radius: 20px;
          top: 4px;
          right: 4px;
          color: #fff;

          &.doubleCount {
            width: 28px;
          }
          &.tripleCount {
            width: 32px;
          }
        }

        //.counterSm
        //.counterMd
        //.counterLg

      }

      .menuListItem.active svg g {
        fill: #fff;
        transition: fill .2s ease;
      }
    }

    .menuHeader {
      width: 60px;
      min-height: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .menuFooter {
    cursor: pointer;
    width: 60px;
    //height: 70px;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.menuList {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.menuListItemFooter {
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  color: #5c5e66;
  transition: all .2s ease;
  text-decoration: none;

  &:hover {
    color: #fff;

    .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6 {
      color: #fff;
    }

    svg g {
      fill: #fff;
      transition: fill .2s ease;
    }
  }

  svg g {
    fill: #5c5e66;
    transition: fill .2s ease;
  }

}



