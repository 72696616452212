.wrap div[class^="chrome-picker"] {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.wrap div[class^="chrome-picker"] > div:first-of-type {
    padding-bottom: 45% !important;
}

.wrap div[style^="width: 32px; text-align: right; position: relative;"] {
display: none !important;
}

.boxWrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 !important;
    padding: 0 !important;
}

.box {
    position: relative;
    display: block;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    border-radius: 5px;
}

.text {
    position: relative;
}









