@import "../../variables";

// switcher container
.switcherContainer {
  position: relative;
  display: inline-block;
}

.switcher {
  display: block;
  width: 40px;
  height: 24px;
  border-radius: 14px;
  border: none;
  outline: 0;
  position: relative;
  cursor: pointer;
  transition: all .3s ease;
  background-color: $light-200;
  box-sizing: border-box;
}

.switcherCheckbox {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  z-index: 1000;
}

.switcherCheckbox:checked ~ .switcher:after {
  left: calc(100% - 2px);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.switcherCheckbox:checked ~ .switcher {
  background-color: #83b067!important;
}

.switcher:after {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 14px;
  background-color: $white;
  position: absolute;
  left: 2px;
  top: 2px;
  transition: all .2s ease;
}


.switcherCheckbox:checked:active ~ .switcher {
  background-color: #709e53 !important;
}

.switcherCheckbox:active ~ .switcher {
  background-color: #9d9d9e !important;
}

// size
.sm .switcher{
  width: 32px;
  height: 20px;
}

.sm .switcher:after {
  width: 16px;
  height: 16px;
}

//disabled
.disabled {
  pointer-events: none!important;
  opacity: .7;
}
