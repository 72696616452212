.content {
    position: relative;
    display: block;
    margin-top: 10px;
    cursor: pointer;
}

.desc {
    position: relative;
    display: block;
    color: #646566;
    margin-bottom: 20px;
    font-size: 12px;
}

.item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.vertical {
    display: flex;
    flex-direction: column;
}

.vertical label {
    margin-bottom: 10px;
}
