@import "../../variables";

//popup overlay
.popupOverlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(27, 30, 38, .5);
  transition: all .2s ease;
  opacity: 0;
  z-index: 1060;
  visibility: hidden;
  overflow: auto;
}

// popup container
.popupContainer {
  display: flex;
  align-items: center;
  min-height: calc(100% - 3.5rem);
}

// popup
.popup {
  position: relative;
  width: 620px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  box-shadow: 0 20px 32px 8px rgba(37, 40, 50, 0.2);
  border-radius: 5px;
  padding: 40px;
  margin: 1.75rem auto;
  box-sizing: border-box;
  font-size: 16px;
  font-family: $main-fonts;
  -webkit-transform: scale(.7);
  transform: scale(.7);
  opacity: 0;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.noPadding.popup {
  padding: 0;
}

//show overlay and popup
.open {
  opacity: 1;
  transition: all .2s ease;
  visibility: visible;
}

.open .popup{
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

// close button
.closeButton {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 11px;
  top: 11px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButton:hover i {
  color: $black;
}

.closeButton i {
  font-size: 10px;
  color: $clear-button-color;
  margin: 0 !important;
  padding: 0;
  transition: color .2s ease;
}

.closeButton i:before {
  margin: 0 !important;
  padding: 0;
}

.popup .subtitle {
  font-size: 16px;
  font-weight: 500;
}

