@import "../../variables";

// legend container
.legendContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  box-sizing: border-box;
}

// legend
.legend {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-right: 32px;
  box-sizing: border-box;
  font-family: $main-fonts;
}

// icon
.legendIcon {
  border-radius: 50%;
  margin-right: 6px;
  width: 8px;
  height: 8px;
}

// text
.legendText {
  margin-bottom: 1px;
  font-size: 12px;
}

.legendText p {
  margin: 0;
  padding: 0;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  color: #7d7e80;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

// container section
.legendContainerSection .legend:last-of-type {
  margin-right: 0;
}

.legendContainerSection {
  box-sizing: border-box;
  display: inline-flex;
}