@import "../../variables";

.avatarContainer {
  position: relative;
  display: block;
  float: left;
  width: 48px;
  min-width: 48px;
  height: 48px;
  background: 0 0;
  font-size: 14px;
  box-sizing: border-box;
  font-family: $main-fonts;
}

.avatar {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  text-transform: uppercase;
  color: $black;
  background: $white;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #d4d5d9;
  border-radius: 50%;
  box-sizing: border-box;
  overflow: hidden;
}

.avatar p {
  padding: 0;
  margin: 0;
}

.avatar img {
  height: 100%;
  min-width: 100%;
}

//status
.statusOnline {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid $white;
  background: $green;
  box-sizing: border-box;
}

.statusOffline {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid $white;
  background: $red;
  box-sizing: border-box;
}

//pending img
.avatar svg {
  fill: $light-200;
  width: 50%;
  max-width: 27px;
}

// inner avatar
.channel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 16px;
  height: 16px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid $white;
  background-color: $white;
  z-index: 2;
}

.channel p {
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.channel img {
  width: 100%;
}

// border none
.borderNone .avatar {
  border: none !important;
}

.gradientAvatar {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0.03;
  background-image: linear-gradient(-90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: -moz-linear-gradient(-90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: -webkit-linear-gradient(-90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
}

.dark .avatar {
  background-color: #1b1e26;
  color: #5c5e66;
  border-color: #5c5e66;
}

.dark .statusOnline {
  border-color: #1b1e26;
}

.dark .statusOffline {
  border: 2px solid #1b1e26;
}

.size100.dark .statusOnline, .size100.dark .statusOffline {
  border-color: #1b1e26;
}


.dark .avatar .gradientAvatar {
  display: none;
}


// sizes

//size 16
.size16 {
  width: 16px;
  min-width: 16px;
  height: 16px;
}

.size16 .avatar {
  font-size: 8px;
}

.size16 .statusOnline, .size16 .statusOffline {
  display: none;
}

.size16 .channel {
  display: none;
}

//.size16 .avatar p {
//  width: 1ch;
//  overflow: hidden;
//  white-space: nowrap;
//}

//size 20
.size20 {
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.size20 .avatar {
  font-size: 10px;
}

//size 24
.size24 {
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.size24 .avatar {
  font-size: 10px;
}

.size24 .statusOnline, .size24 .statusOffline {
  bottom: -2px;
  right: -2px;
  width: 10px;
  height: 10px;
}

.size24 .channel {
  display: none;
}

//.size24 .avatar p {
//  width: 1ch;
//  overflow: hidden;
//  white-space: nowrap;
//}

//size 32
.size32 {
  width: 32px;
  min-width: 32px;
  height: 32px;
}

.size32 .avatar {
  font-size: 12px;
}

.size32 .statusOnline, .size32 .statusOffline {
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
}

.size32 .channel {
  bottom: -4px;
  right: -4px;
  width: 12px;
  height: 12px;
  border: 2px solid $white;
}

//size 40
.size40 {
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.size40 .statusOnline, .size40 .statusOffline {
  bottom: 0;
  right: 0;
  width: 12px;
  height: 12px;
}

.size40 .channel {
  bottom: -4px;
  right: -4px;
  width: 16px;
  height: 16px;
  border: 2px solid $white;
}

//size 48
.size48 {
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.size48 .avatar {
  font-size: 14px;
}

.size48 .statusOnline, .size48 .statusOffline {
  bottom: 2px;
  right: 2px;
  width: 12px;
  height: 12px;
}

.size48 .channel {
  bottom: -1px;
  right: -2px;
  width: 16px;
  height: 16px;
  border: 2px solid $white;
}

//size 80
.size80 {
  width: 80px;
  min-width: 80px;
  height: 80px;
}

//size 100
.size100 {
  width: 100px;
  min-width: 100px;
  height: 100px;
}

.size100 .avatar {
  font-size: 24px;
}

.size100 .statusOnline, .size100 .statusOffline {
  bottom: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
  border: 4px solid #fff;
}

.size100 .channel {
  bottom: -4px;
  right: -4px;
  width: 24px;
  height: 24px;
  border: 4px solid $white;
}

// noGradient
.noGradient .gradientAvatar {
  display: none!important;
}

//noName
.avatar.noName {
  background-position: center center;
  background-repeat: no-repeat;
}
.size100 .noName {
  background-image: url("no-name-100.svg");
}

.size48 .noName {
  background-image: url("no-name-48.svg");
}

.size40 .noName {
  background-image: url("no-name-40.svg");
}

.size32 .noName {
  background-image: url("no-name-32.svg");
}

.size24 .noName {
  background-image: url("no-name-32.svg");
}
