@import "../../variables";

// popup footer
.popupFooter {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-top: 24px;
}

.popupFooter button:first-child {
  margin-right: 10px;
}