@import "../../variables";


// notifications
.notifications {
  position: fixed;
  top: 0;
  right: 0;
  padding: 20px 16px 54px 80px;
  display: block;
  width: 472px;
  box-sizing: border-box;
  max-width: 536px;
  max-height: 100vh;
  margin-right: 60px;
  transition: all .2s ease;
  z-index: 1001;
}

// remove scrollbar in notifications
.notifications {
  -ms-overflow-style: none;
}

.notifications::-webkit-scrollbar {
  width: 0 !important;
}

.notifications {
  overflow: -moz-scrollbars-none;
}

.notifications {
  -ms-overflow-style: none;
}

.notifications {
  scrollbar-width: none;
}

// notification
.notification {
  width: 100%;
  max-width: 376px;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-radius: 5px;
  background-color: $white;
  margin-bottom: 12px;
  font-size: 12px;
  box-shadow: 0 0 0 1px $light-700;
  transition: all .2s ease;
  position: relative;
  will-change: auto;
  font-family: $main-fonts;
}

.notification:hover {
  box-shadow: 0 20px 32px 8px rgba(27, 30, 38, .2);
  z-index: 55;
}

// header
.notificationHeader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 12px 16px;
  border-bottom: 1px solid $light-600;
}

.headerInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  min-width: 40%;
}

.notificationLabel {
  margin-right: 8px;
}

.notificationEvent {
  width: 100%;
  max-width: 236px;
  min-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 14px;
}

// status
.notificationStatus {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
}

.notificationStatus.open:after,
.notificationStatus.new:after,
.notificationStatus.closed:after {
  position: absolute;
  height: 15px;
  font-size: 12px;
  line-height: 1 !important;
}

// status new
.notificationStatus.new {
  background-color: $red;
}

.notificationStatus.new:after {
  content: 'n';
}

// status open
.notificationStatus.open {
  background-color: $yellow;
}

.notificationStatus.open:after {
  content: 'o';
}

// status closed
.notificationStatus.closed {
  background-color: #bdbebf;
}

.notificationStatus.closed:after {
  content: 'c';
}

// status note
.notificationStatus.note {
  background-color: $green;
}

.notificationStatus.note:after {
  content: '\e814';
  font-family: design-system;
  font-size: 4px;
  color: $white;
}

// status reassigned
.notificationStatus.reassigned {
  background-color: #bdbebf;
}

.notificationStatus.reassigned:after {
  content: '\e815';
  font-family: design-system;
  font-size: 10px;
  color: $white;
}

// time
.notificationTime {
  font-size: 12px;
  line-height: 14px;
  color: $light-100;
  width: 80px;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

// close button
.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: $white;
  transition: all .2s ease;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.notification:hover .closeButton {
  z-index: 2 !important;
  opacity: 1 !important;
  visibility: visible !important;
}

.closeButton i {
  font-size: 10px;
  color: #8c8d8e;
  transition: all .2s ease;
}

.closeButton i:before {
  margin: 0;
  padding: 0;
}

.closeButton i:hover {
  color: $black;
  cursor: pointer;
}

// body
.notificationBody {
  padding: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  position: relative;
}

.bodyInfo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-width: 40%;
  height: 42px;
}

.avatarContainer {
  height: 42px;
  min-width: 80px;
  max-width: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.notificationName {
  width: 100%;
  max-width: 260px;
  min-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-weight: 600;
  line-height: 14px;
}

.notificationMessage {
  width: 100%;
  max-width: 260px;
  min-width: 160px;
  padding-top: 6px;
}

.ellipses {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.avatarContainer {
  min-width: 80px;
  max-width: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

// buttons
.notificationButtons {
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
  height: 100%;
  background-color: $white;
  border-left: 1px solid $light-600;
  color: $light-100;
  transition: all .2s ease;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  border-radius: 0 0 5px 0;
}

.buttonMute {
  min-height: 32px;
  height: 50%;
  border-bottom: 1px solid $light-600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: color .2s ease;
}

.buttonClose {
  min-height: 32px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: color .2s ease;
}

// additional message
.additionalMessage {
  padding: 0 16px;
  background-color: #f2f4f7;
  font-size: 10px;
  color: #656566;
  min-width: 160px;
  max-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0 0 5px 5px;
}

.additionalMessage p {
  padding: 4px 0;
  margin: 0;
  min-width: 160px;
  max-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

// push
.push {
  box-shadow: 0 20px 32px 8px rgba(27, 30, 38, .2);
  border: none;
}

.push:hover .notificationButtons {
  z-index: 2;
  visibility: visible;
  opacity: 1;
}

.push .closeButton {
  display: none;
}

// push hovered show buttons
.buttonMute:hover {
  color: $black;
}

.buttonClose:hover {
  color: $black;
}

// notifications animation
.notification {
  transform: translateX(500px);
  -webkit-transform: translateX(500px);
  -moz-transform: translateX(500px);
  animation: push-in .2s alternate .1s;
  animation-fill-mode: both;
}

.notification.hide {
  animation: push-out .2s alternate !important;
  animation-fill-mode: forwards !important;
}

// animation show notification
@keyframes push-in {
  0% {
    transform: translateX(500px);
    -moz-transform: translateX(500px);
    -webkit-transform: translateX(500px);
    -ms-transform: translateX(500px);
    height: 0;
    margin-bottom: 0;
  }

  50% {
    height: 105px;
    transform: translateX(500px);
    -moz-transform: translateX(500px);
    -webkit-transform: translateX(500px);
    -ms-transform: translateX(500px);
    margin-bottom: 12px;
  }

  100% {
    transform: translateX(0);
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
  }
}

// animation hide notification
@keyframes push-out {
  0% {
    height: 105px;
    margin-bottom: 12px;
    transform: translateX(0);
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
  }

  50% {
    height: 105px;
    transform: translateX(500px);
    -moz-transform: translateX(500px);
    -webkit-transform: translateX(500px);
    -ms-transform: translateX(500px);
    margin-bottom: 12px;
  }

  100% {
    transform: translateX(500px);
    -moz-transform: translateX(500px);
    -webkit-transform: translateX(500px);
    -ms-transform: translateX(500px);
    height: 0;
    margin-bottom: 0;
  }
}
