.title {
    position: relative;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    height: 80px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    padding: 0 24px;
    margin: 0;
}

.copy {
    position: absolute;
    bottom: 24px;
    padding: 0 24px;
    color: rgb(85, 85, 85);
}