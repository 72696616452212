.item {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 35px;
    padding: 0 24px;
    font-weight: 400;
    color: #646566;
}

.item a {
    position: absolute;
    cursor: inherit;
    pointer-events: inherit;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.decor {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #000;
    transition: .3s ease;
    opacity: 0;
}

.item:hover .decor {
    opacity: 1;
}

.active, .complete {
    color: #000000;
    font-weight: 500;
}

.active .decor {
    opacity: 1;
}

.blocked {
    pointer-events: none;
    cursor: default;
}