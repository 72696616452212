@import "../../variables";

// switcher container
.checkboxContainer{
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-family: $main-fonts;
}

.checkboxContainer:hover > .inputCheckbox {
  border-color: $light-200;
}

.inputCheckbox {
  position: relative;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  -webkit-transition: .2s ease;
  transition: .2s ease;
  border: 1px solid #dbdde0;
  box-sizing: border-box;
}

.checkboxInput {
  cursor: pointer;
  opacity: 0;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
}

.checkboxSvgContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: .15s ease;
  transition: .15s ease;
  opacity: 0;
}

.inputCheckboxFlag {
  pointer-events: none;
  user-select: none;
}

.checkboxInput:checked ~ .checkboxSvgContainer {
  opacity: 1;
}

.checkboxLabel {
  color: $black;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 10px;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
}

//round form
.roundForm .inputCheckbox{
  border-radius: 50% !important;
}

// disabled
.disabled{
  pointer-events: none !important;
}

.disabled .inputCheckbox{
  pointer-events: none !important;
  background-color: #d4d5d9;
}

//small size
.checkboxContainer.sm .checkboxLabel {
  font-size: 12px;
}
