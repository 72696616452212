.wrap {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    border-top: 1px solid #e1e3e6;
    border-bottom: 1px solid #e1e3e6;
}

.check {
    display: block;
    margin: 0;
    padding: 0;
    line-height: 0;
}

.noBorderBottom {
    border-bottom: none;
}
