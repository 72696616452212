@import "fonts/fonts.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Source Sans Regular",
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: -apple-system, BlinkMacSystemFont, "Source Sans Regular",
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

[data-react-beautiful-dnd-drag-handle] ,
[data-react-beautiful-dnd-draggable]
{
  margin-bottom: 10px;
}

/*.anim {*/
/*  opacity: 0;*/
/*  transform: scale(.9);*/
/*  transition: .25s ease;*/
/*}*/

/*.anim.go {*/
/*  opacity: 1;*/
/*  transform: scale(1);*/
/*}*/
