.layout {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100vh;
    background: #edeff2;
}

.content {
    position: relative;
    width: calc(100% - 300px);
    height: 100%;
    background: #ffffff;
    overflow: auto;
    box-shadow: 0 0 10px 3px rgba(33,33,33,.1);
    border-radius: 10px 0 0 10px;
}

.subMenu {
    width: 100%;
}

.settings {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
}
