@import "../../variables";

// alert container
.alertContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  max-width: 620px;
  width: 80%;
  background-color: transparent;
  z-index: -1;
  opacity: 0;
  top: 20px;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
  font-family: $main-fonts;
}

// container show
.alertContainer.show {
  opacity: 1;
  z-index: 1060;
}

// alert
.alert {
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 16px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  transition: background .4s ease-in-out, box-shadow .4s ease, color .2s ease;
  color: $black;
  background-color: $white;
  box-shadow: 0 20px 32px 8px rgba(26, 30, 39, .1);
  display: flex;
  animation: alertSlideIn .8s alternate;
  animation-fill-mode: forwards;
  transform-origin: top right;
  z-index: 1061;
}

// alert hide
.alert.hide {
  animation: alertSlideOut .8s alternate;
  animation-fill-mode: forwards;
}

.content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 96%;
  animation: fade .4s ease-in-out;
  animation-fill-mode: forwards;
}

.alert i {
  font-size: 8px;
  cursor: pointer;
  color: #c5c6c7;
  transition: all .2s ease;
  //animation: fade .4s ease-in-out;
  //animation-fill-mode: forwards;
}

.alert i:hover {
  color: #45474d;
}

.text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0 0 2px 0;
  font-size: 14px;
}

.icon {
  margin-right: 8px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon svg {
  fill: $white;
}

// status
.alert.success {
  background-color: $green;
  box-shadow: 0 20px 32px 8px rgba(101, 170, 75, .2);
  color: $white;
}

.alert.success i {
  color: $white;
  opacity: 0.6;
}

.alert.success i:hover {
  opacity: 1;
}

.alert.warning {
  background-color: $yellow;
  box-shadow: 0 20px 32px 8px rgba(202, 173, 39, .2);
  color: $white;
}

.alert.warning i {
  color: $white;
  opacity: 0.6;
}

.alert.warning i:hover {
  opacity: 1;
}

.alert.error {
  background-color: $red;
  box-shadow: 0 20px 32px 8px rgba(211, 0, 67, .2);
  color: $white;
}

.alert.error i {
  color: $white;
  opacity: 0.6;
}

.alert.error i:hover {
  opacity: 1;
}


// animation alert show
@keyframes alertSlideIn {
  0% {
    opacity: 0;
    height: 0;
    padding: 0 16px;
    transform: translateY(-30px);
  }

  30% {
    opacity: 0.3;
    height: 40px;
    padding: 16px;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }

}

// animation alert hide
@keyframes alertSlideOut {
  0% {
    opacity: 1;
    transform: translateY(0);
    height: 40px;
    padding: 16px;
  }

  20% {
    opacity: 0;
    //transform: translateY(-20px);
  }

  50% {
    height: 0;
    padding: 0;
    margin: 0;
  }

  100% {
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
    //transform: translateY(-20px);
  }

}

// fade animation to alert content
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}